export default (theme) => ({
  menu: {
    alignItems: 'flex-end',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  menuToolbar: {
    height: 68,
    padding: 0,
  },
  menuTypography:{},
  menuLink:{
    display: 'flex',
    alignItems: 'center',
    marginLeft: 30,
    fontFamily: "'Avenir-Roman'",
    fontSize: 18,
    color: '#504F53',
    textDecoration: 'none',

  },
  menuLinkIcon: {
    marginRight: 4,
  },
  generateButton: {
    marginLeft: 30,
  },
  btnText: {
    verticalAlign: 'text-bottom',
    marginLeft: 10
  }
})