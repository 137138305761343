export default () => ({
  errorDetailsContainer: {
    '& p': {
      fontSize: 18,
      '& b': {
        fontFamily: 'Avenir',
        color: '#504F53'
      }
    },
  },
  modalTitle: {
    '& > h6': {
      color: '#9F3A7E',
      fontSize: 25,
      fontFamily: 'Avenir'
    }
  }
})