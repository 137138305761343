import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import styles from './styles';


function Footer(props) {
  const { classes } = props;

  return (
    <footer className={classes.footer}>
      <Grid container className={classes.container}>
        <Grid item xs={6} md={4} lg={3} className={classes.footerItem}>
          <span className={classes.footerText}><b>Evergreen VIOLET</b> - Powered by <b>Fincite</b></span>
        </Grid>
        <Grid item xs={6} md={4} lg={3} className={classes.footerItem}>
          <span className={classes.footerText}>
            <b>
              Contact Info: info@fincite.de
            </b>
          </span>
        </Grid>
      </Grid>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
