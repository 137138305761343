import { scrollStyle } from '../../components/BacktestOverview/styles';

export default function(theme) {
  return {
    mainContainer: {
      width: '100%',
      maxWidth: 1280,
      padding: '0 0 116px 0',
      margin: '0 auto',
      height: 'calc(100vh - 110px)',
      minHeight: 600,
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        padding: '0px 16px',
        paddingBottom: 122
      }
    },
    circularProgress: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    datePickerSelectedDay: {
      backgroundColor: '#9F3A7E'
    },
    datePickerInput: {
      lineHeight: '2px',
      '& button': {
        marginLeft: -30
      }
    },
    tableContainer: {
      height: '100%',
      width: '100%',
      maxWidth: '100%',
      boxShadow: '-1px -1px 143px -30px rgba(0,0,0,0.18)',
      borderRadius: 10,
      overflow: 'auto',
      ...scrollStyle
    }
  };
}