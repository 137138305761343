import React from 'react';
import _ from 'lodash';

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from '../../containers/FundOverview/styles';

import { BackendReource } from '../../api/index_v2';
import TradeSheetsTable from '../../components/TradeSheets/Table';
import axios from "axios";
import * as axiosUtils from "../../utils/axiosUtils";
import FileDownload from "js-file-download";
import Snackbar from "../../components/Snackbar";


class TradeSheets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tradeSheets: null,
      popupVariant: '',
      popupMessage: '',

    };

    this.fetchTradeSheets = this.fetchTradeSheets.bind(this);
    this.handleExportTradeSheet = this.handleExportTradeSheet.bind(this);
    this.showPopup = this.showPopup.bind(this);
  }

  componentDidMount() {
    this.fetchTradeSheets()
  }

  showPopup() {
    this.setState({
      popupOpen: true
    });
    setTimeout(() => {
      this.setState({
        popupOpen: false
      });
    }, 3000);
  }

  fetchTradeSheets() {
    BackendReource.at('trade_sheets/').get().then(response => {
      const tradeSheets = _.isString(response.data) ? JSON.parse(response.data.replace(/\bNaN\b/g, "null")) : response.data;
      this.setState({
        tradeSheets: tradeSheets,
        loading: false
      });
    }).catch((error) => {
      this.setState({
        popupVariant: "error",
        popupMessage: 'Generate Trade Sheet before'
      });
      this.showPopup();
    });
  }

  handleExportTradeSheet = (trade_sheet_id) => {
    axios.get(`/api/v2/evergreen_backend/trade_sheet/download/?trade_sheet_id=${trade_sheet_id}`, {
      responseType: 'arraybuffer'
    }).then((response) => {
      let filename = axiosUtils.getFileNameFromResponse(response);
      if (filename) {
        FileDownload(new Blob([response.data]), filename);
      }
    }).catch((error) => {
      this.setState({
        popupVariant: "error",
        popupMessage: 'The file is missing'
      });
      this.showPopup();
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.mainContainer}>
        <Snackbar
          variant={this.state.popupVariant}
          message={this.state.popupMessage}
          snackbarOpen={this.state.popupOpen}
        />
        {this.state.loading ? (
          <Grid className={classes.circularProgress}>
            <CircularProgress  size={30}/>
          </Grid>
        ) : (
          <React.Fragment>
            <div className={classes.MenuPlaceholsed} style={{height: 68}}/>
            <TradeSheetsTable
              tradeSheets={this.state.tradeSheets}
              handleExportTradeSheet={this.handleExportTradeSheet}
            />
          </React.Fragment>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(TradeSheets);