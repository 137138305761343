import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { DateFormatInput } from 'material-ui-next-pickers'

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FileDownload from 'js-file-download';

import styles from '../FundOverview/styles';
import backtestOverviewSyles from './styles';
import theme from '../../themes/mainTheme';
import BacktestOverviewTable from '../../components/BacktestOverview/Table';
import { BackendReource } from '../../api/index_v2';
import { CALCULATION_MESSAGE_TYPE, BACKTEST_STATUS } from './constants';
import BacktestCalculationErrorInfo from '../../components/BacktestCalculationErrorInfo';
import { dailyCalculationScheme, longTermCalculationScheme } from './schemes';
import Menu from "../../components/BacktestOverview/Menu";
import * as axiosUtils from '../../utils/axiosUtils';

class BacktestOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backtestsOverview: [],
      isLoading: true,
      errorModal:  {
        open: false,
        backtestId: undefined
      },
      open: false,
      selectBacktest: undefined,
      dateRange: {
        longTerm: {
          startDate: new Date(Date.parse('01/01/2015')),
          endDate: moment(new Date()).format('YYYY-MM-DD')
        },
        daily: {
          startDate: moment(new Date()).format('YYYY-MM-DD')
        }
      },
      dailyCalculationLoading: false,
      longTermCalculationLoading: false,
      taskInfo: {
        isLoading: false,
        taskExists: false
      },
      historicalDataExists: false,
      historicalDataDeleting: false,
      errors: [],
      socket: null,
      historicalDataDeletingConfirmOpened: false,
      stopCalculation: {
        backtestId: undefined,
        open: false,
        loading: false
      }
    };

    this.fetchBacktestOverviewData = this.fetchBacktestOverviewData.bind(this);
    this.sortBacktestsOverview = this.sortBacktestsOverview.bind(this);
    this.startWebsocketConnection = this.startWebsocketConnection.bind(this);
    this.changeBacktestData = this.changeBacktestData.bind(this);
    this.changeBacktestProgress = this.changeBacktestProgress.bind(this);
    this.changeBacktestStatus = this.changeBacktestStatus.bind(this);
    this.changeBacktestCalculationData = this.changeBacktestCalculationData.bind(this);
    this.renderBacktestOverviewTable = this.renderBacktestOverviewTable.bind(this);
    this.renderBacktestCalculationInfoModal = this.renderBacktestCalculationInfoModal.bind(this);
    this.openErrorModal = this.openErrorModal.bind(this);
    this.closeErrorModal = this.closeErrorModal.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleDateChange  =this.handleDateChange.bind(this);
    this.handleRunDaily = this.handleRunDaily.bind(this);
    this.handleRunLongterm = this.handleRunLongterm.bind(this);
    this.handleDeleteHistoricalClick = this.handleDeleteHistoricalClick.bind(this);
    this.handleDownloadHistoricalClick = this.handleDownloadHistoricalClick.bind(this);
    this.handleStopCalculationClick = this.handleStopCalculationClick.bind(this);
    this.handleStopCalculationModalClose = this.handleStopCalculationModalClose.bind(this);
    this.handleStopCalculationConfirmClick = this.handleStopCalculationConfirmClick.bind(this);
    this.handleExportClick = this.handleExportClick.bind(this);
    this.allowRunning = this.allowRunning.bind(this);
    this.disableRunning = this.disableRunning.bind(this);
    this.setHistoricalConfirmVisibility = this.setHistoricalConfirmVisibility.bind(this);
  }

  setHistoricalConfirmVisibility(visibility) {
    this.setState({
      historicalDataDeletingConfirmOpened: visibility
    });
  }

  handleStopCalculationClick = backtestId => () => {
    this.setState({
      stopCalculation: {
        backtestId,
        open: true
      }
    });
  }

  handleStopCalculationConfirmClick() {
    this.setState({
      stopCalculation: {
        ...this.state.stopCalculation,
        loading: true
      }
    });
    BackendReource.at(`backtest/${this.state.stopCalculation.backtestId}/`)
      .delete()
      .then(response => {
        this.setState({
          stopCalculation: {
            backtestId: undefined,
            open: false,
            stopCalculation: false
          }
        });
      });
  }

  handleStopCalculationModalClose() {
    this.setState({
      stopCalculation: {
        backtestId: undefined,
        open: false
      }
    });
  }

  handleExportClick(event) {
    event.preventDefault();

    axios.get('/api/v2/evergreen_backend/backtest_overview/download/', {
      responseType: 'arraybuffer'
    }).then((response) => {
      let filename = axiosUtils.getFileNameFromResponse(response);
      if (filename) {
        FileDownload(new Blob([response.data]), filename);
      }
    });
  }

  handleDownloadHistoricalClick() {
    BackendReource.at(`historical_data/${this.state.selectBacktest}/download/`).get().then((response) => {
      let filename = axiosUtils.getFileNameFromResponse(response);
      if (filename) {
        FileDownload(response.data, filename);
      }
    });
  }

  handleDeleteHistoricalClick() {
    this.setState({
      historicalDataDeleting: true
    })
    BackendReource.at(`historical_data/${this.state.selectBacktest}/`).delete().then(() => {
      this.setState({
        historicalDataExists: false,
        historicalDataDeleting: false,
        historicalDataDeletingConfirmOpened: false
      });
    }); 
  }

  handleDateChange = (dateType, dateName) => date => {
    this.setState({
      dateRange: {
        ...this.state.dateRange,
        [dateType]: {
          ...this.state.dateRange[dateType],
          [dateName]: date
        }
      }
    });
  }

  handleModalOpen = id => event => {
    this.setState({
      open: true,
      selectBacktest: id,
      taskInfo: {
        ...this.state.taskInfo,
        isLoading: true
      },
      dateRange: {
        longTerm: {
          startDate: new Date(Date.parse('01/01/2015')),
          endDate: new Date(new Date().setDate(new Date().getDate()-1))
        },
        daily: {
          startDate: new Date(new Date().setDate(new Date().getDate()-2))
        }
      },
      historicalDataExists: false,
      historicalDataDeletingConfirmOpened: false,
      errors: []
    });

    const fetchHistoricalData = () => {
      BackendReource.at(`historical_data/${id}/`).get().then((response) => {
        this.setState({
          historicalDataExists: response.data.data_exists,
          isLoading: false
        });
      }).catch(() => {
        this.setState({
          historicalDataExists: false,
          isLoading: false
        })
      })
      this.setState({
        taskInfo: {
          taskExists: false,
        }
      });
    }

    BackendReource.at(`backtest/${id}/`).get().then(() => {
      fetchHistoricalData();
    }).catch(() => {
      fetchHistoricalData();
      this.setState({
        taskInfo: {
          taskExists: true,
          isLoading: false
        }
      });
    });
  }

  handleModalClose = event => {
    if (!this.state.longTermCalculationLoading && !this.state.dailyCalculationLoading) {
      this.setState({
        open: false,
        selectBacktest: undefined,
        taskInfo: {
          taskExists: false,
          isLoading: false
        },
      });
    }
  }

  handleRunDaily = event => {
    const { dateRange: { daily } } = this.state;

    this.setState({
      dailyCalculationLoading: true
    });
    dailyCalculationScheme.validate(daily, {abortEarly: false}).then(() => {
      let data = {
        start_date: moment(this.state.dateRange.daily.startDate).format('YYYY-MM-DD'),
        end_date: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
      }

      BackendReource.at(`backtest/${this.state.selectBacktest}/`).post(data).then((response) => {
        this.setState({
          dailyCalculationLoading: false,
          open: false
        });
      }).catch((err) => {
        this.setState({
          dailyCalculationLoading: false
        });
      });
    }).catch((err) => {
      const errors = {}
        err.inner.map((error) => {
          errors[error.path] = error.message;
          return null;
        });
      this.setState({
        errors: {
          ...this.state.errors,
          daily: {
            ...errors
          }
        },
        dailyCalculationLoading: false
      })
    });
  }

  handleRunLongterm = event => {
    const { dateRange: { longTerm } } = this.state;
    
    this.setState({
      longTermCalculationLoading: true
    });
    longTermCalculationScheme.validate(longTerm, {abortEarly: false}).then(() => {
      let data = {
        start_date: moment(this.state.dateRange.longTerm.startDate).format('YYYY-MM-DD'),
        end_date: moment(this.state.dateRange.longTerm.endDate).format('YYYY-MM-DD'),
      }

      BackendReource.at(`backtest/${this.state.selectBacktest}/`).post(data).then((response) => {
        this.setState({
          longTermCalculationLoading: false,
          open: false
        });
      }).catch((err) => {
        this.setState({
          longTermCalculationLoading: false
        });
      });
    }).catch((err) => {
      const errors = {}
        err.inner.map((error) => {
          errors[error.path] = error.message;
          return null;
        });
      this.setState({
        errors: {
          ...this.state.errors,
          longTerm: {
            ...errors
          }
        },
        longTermCalculationLoading: false
      })
    });
  }

  componentDidMount() {
    this.fetchBacktestOverviewData();

    const path = `${window.location.protocol === "https:" ? "wss:" : "ws:"}//${window.location.host}/socket/`;

    this.startWebsocketConnection(path);
  }

  closeErrorModal() {
    this.setState({
      errorModal: {
        open: false,
        backtestId: undefined
      }
    });
  }

  openErrorModal(backtestId) {
    return (event) => {
      this.setState({
        errorModal: {
          open: true,
          backtestId
        }
      })
    }
  }

  componentWillUnmount() {
    let { socket } = this.state;
    socket.onclose = () => {}
    socket.close();
    this.setState({
      socket
    });
  }

  startWebsocketConnection(location) {
    let socket = new WebSocket(location);

    socket.onmessage = (message) => {
      const data = JSON.parse(message.data);
      
      switch(data.type) {
        case CALCULATION_MESSAGE_TYPE.START:
          this.changeBacktestStatus(data.backtest, BACKTEST_STATUS.IN_PROGRESS);
          if (+data.backtest === +this.state.selectBacktest) {
            this.disableRunning()
          }
          break;
        case CALCULATION_MESSAGE_TYPE.PROGRESS:
          this.changeBacktestProgress(data.backtest, data.progress, data.message);
          break;
        case CALCULATION_MESSAGE_TYPE.DONE:
          this.changeBacktestData(JSON.parse(_.isString(data.backtest) ? data.backtest.replace(/\bNaN\b/g, "null") : data.backtest)['bt_id'], data.backtest);
          if (+JSON.parse(_.isString(data.backtest) ? data.backtest.replace(/\bNaN\b/g, "null") : data.backtest)['bt_id'] === +this.state.selectBacktest) {
            this.allowRunning()
          }
          break;
        case CALCULATION_MESSAGE_TYPE.FAIL:
          this.changeBacktestStatus(data.backtest, BACKTEST_STATUS.FAILURE);
          this.changeBacktestCalculationData(data.backtest, {
            message: data.message,
            execution_time: data.execution_time
          });
          if (+data.backtest === +this.state.selectBacktest) {
            this.allowRunning()
          }
          break;
        case CALCULATION_MESSAGE_TYPE.NEW:
          this.changeBacktestStatus(data.backtest, BACKTEST_STATUS.IN_QUEUE);
          if (+data.backtest === +this.state.selectBacktest) {
            this.disableRunning()
          }
          break;
        default: 
          break;
      }
    }

    socket.onclose = () => {
      setTimeout(this.startWebsocketConnection(location), 500);
    }

    this.setState({
      socket
    });
  }

  allowRunning() {
    this.setState({
      taskInfo: {
        ...this.state.taskInfo,
        taskExists: false
      }
    })
  }

  disableRunning() {
    this.setState({
      taskInfo: {
        ...this.state.taskInfo,
        taskExists: true
      }
    })
  }

  changeBacktestCalculationData(backtestId, data) {
    let backtests = this.state.backtestsOverview;

    for(let i = 0; i < backtests.length; i++) {
      if (+backtests[i].bt_id === +backtestId)  {
        backtests[i].calculation = {
          ...backtests[i].calculation,
          ...data
        };
      }
    }

    this.setState({
      backtestsOverview: backtests
    });
  }

  changeBacktestStatus(backtestId, status) {
    let backtests = this.state.backtestsOverview;

    let setStatus = (backtest, status) => {
      if (backtest.calculation) {
        backtest.calculation.status = status;
      }
      else {
        backtest.calculation = {
          status
        }
      }
    }

    for(let i = 0; i < backtests.length; i++) {
      if (backtests[i].bt_id === parseInt(backtestId))  {
        switch (status) {
          case BACKTEST_STATUS.IN_QUEUE:
            if (!backtests[i].calculation || (backtests[i].calculation && backtests[i].calculation.status !== BACKTEST_STATUS.IN_PROGRESS)) {
              setStatus(backtests[i], status);
            }
            break;
          default:
            setStatus(backtests[i], status);
            break;
        }
      }
    }

    this.setState({
      backtestsOverview: backtests
    });
  }

  changeBacktestProgress(backtestId, progress, message) {
    let backtests = this.state.backtestsOverview;

    for(let i = 0; i < backtests.length; i++) {
      if (backtests[i].bt_id === parseInt(backtestId))  {
        backtests[i].calculation.progress = parseFloat(progress);
        backtests[i].calculation.message = message;
      }
    }

    this.setState({
      backtestsOverview: backtests
    });
  }

  changeBacktestData(backtestId, data) {
    let backtests = this.state.backtestsOverview;

    for(let i = 0; i < backtests.length; i++) {
      if (backtests[i].bt_id === backtestId)  {
        backtests[i] = JSON.parse(_.isString(data) ? data.replace(/\bNaN\b/g, "null") : data);
      }
    }

    this.setState({
      backtestsOverview: backtests
    });
  }

  fetchBacktestOverviewData() {
    BackendReource.at('backtest_overview/').get().then((response) => {
      this.setState({
        backtestsOverview: _.isString(response.data) ? JSON.parse(response.data.replace(/\bNaN\b/g, "null")) : response.data,
        isLoading: false
      });
    }).catch((errors) => {
    });
  }

  sortBacktestsOverview(sortedData) {
    this.setState({
      backtestsOverview: sortedData
    });
  }

  renderBacktestOverviewTable() {
    const { isLoading } = this.state;
    const { classes } = this.props;

    if (!isLoading) {
      return <BacktestOverviewTable 
        backtestsOverview={this.state.backtestsOverview} 
        handleSortAction={this.sortBacktestsOverview}
        history={this.props.history}
        openErrorModal={this.openErrorModal}
        handleModalOpen={this.handleModalOpen} 
        handleStopCalculationClick={this.handleStopCalculationClick}/>
    }

    return (
      <Grid className={classes.circularProgress}>
        <CircularProgress size={30} />
      </Grid>
    )
  }

  renderBacktestCalculationInfoModal() {
    const { errorModal: {open, backtestId} } = this.state;

    const backtest = _.find(this.state.backtestsOverview, (backtest) => {
      return backtest.bt_id === backtestId;
    });

    if (backtest) {
      return (
        <BacktestCalculationErrorInfo
          open={open}
          closeModal={this.closeErrorModal}
          backtest={backtest}
        />
      )
    }
  }

  renderStopCalculationModal() {
    const { classes } = this.props;

    let backtest = _.find(this.state.backtestsOverview, (backtest) => {
      return backtest.bt_id === this.state.stopCalculation.backtestId;
    });

    if (backtest) {
      return (
        <Dialog
          open={this.state.stopCalculation.open}
          onClose={this.handleStopCalculationModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>Warning!</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.stopCaclulationModalContent}>
              {backtest.calculation.status === 3 ? "Calculation will be stopped!" : "Calculation will be removed from Queue!"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleStopCalculationModalClose} color="primary">
              Cancel
            </Button>
            <Button 
              onClick={this.handleStopCalculationConfirmClick}
              color="primary"
              variant="contained"
              autoFocus
              disabled={this.state.stopCalculation.loading}
            >
              {
                this.state.stopCalculation.loading ? (
                  <CircularProgress className={classes.circularProgressButton}/>
                ) : "Confirm"
              }
            </Button>
            </DialogActions>
        </Dialog>
      )
    }
  }

  renderModal() {

    if (this.state.selectBacktest) {
      const { classes } = this.props;
      const backtest = _.find(this.state.backtestsOverview, (element) => {
        return element.bt_id === this.state.selectBacktest;
      });

      const isModalEditable = this.state.dailyCalculationLoading || this.state.longTermCalculationLoading || this.state.taskInfo.taskExists || this.state.historicalDataDeleting;
  
      return (
        <Dialog
          open={this.state.open}
          onClose={this.handleModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>{`${backtest.bt_id} - ${backtest.bt_name}`}</DialogTitle>
          <DialogContent>
            {
              this.state.taskInfo.isLoading ? (
                <Grid className={classes.modalLoaderContainer}>
                  <CircularProgress  size={30}/>
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid className={classes.modalSection}>
                    <h3 className={classes.modalSectionTitle}>Longterm</h3>
                      <FormControl className={classes.formControl}>
                        <DateFormatInput 
                          variant="outlined" 
                          label="From"
                          value={this.state.dateRange.longTerm.startDate}
                          onChange={this.handleDateChange('longTerm', 'startDate')}
                          max={new Date()}
                          dateFormat={(date) => moment(date).format('YYYY-MM-DD')}
                          CalendarProps={{
                            classes: {
                              selectedDay: classes.datePickerSelectedDay
                            }
                          }}
                          InputProps={{
                            classes: {
                              root: classes.datePickerInput
                            }
                          }}
                        />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <DateFormatInput 
                          variant="outlined" 
                          label="Till"
                          value={this.state.dateRange.longTerm.endDate}
                          onChange={this.handleDateChange('longTerm', 'endDate')}
                          dateFormat={(date) => moment(date).format('YYYY-MM-DD')}
                          CalendarProps={{
                            classes: {
                              selectedDay: classes.datePickerSelectedDay
                            }
                          }}
                          InputProps={{
                            classes: {
                              root: classes.datePickerInput
                            }
                          }}
                        />
                      </FormControl>
                      <Button 
                        variant="contained" 
                        color="primary"
                        onClick={this.handleRunLongterm}
                        className={classes.runButton}
                        disabled={isModalEditable}
                      >
                        {
                          this.state.longTermCalculationLoading ? (
                            <CircularProgress className={classes.circularProgressButton}/>
                          ) : (
                            <React.Fragment>Run</React.Fragment>
                          )
                        }
                      </Button>
                  </Grid>
                  <Grid className={classes.modalSection}>
                    <h3 className={classes.modalSectionTitle}>Daily</h3>
                      <FormControl className={classes.formControl}>
                        <DateFormatInput 
                          variant="outlined" 
                          label="From"
                          value={this.state.dateRange.daily.startDate}
                          onChange={this.handleDateChange('daily', 'startDate')}
                          max={new Date()}
                          dateFormat={(date) => moment(date).format('YYYY-MM-DD')}
                          CalendarProps={{
                            classes: {
                              selectedDay: classes.datePickerSelectedDay
                            }
                          }}
                          InputProps={{
                            classes: {
                              root: classes.datePickerInput
                            }
                          }}
                        />
                      </FormControl>
                      <Button 
                        variant="contained" 
                        color="primary"
                        onClick={this.handleRunDaily}
                        className={classes.runButton}
                        disabled={isModalEditable}
                      >
                        {
                          this.state.dailyCalculationLoading ? (
                            <CircularProgress className={classes.circularProgressButton}/>
                          ) : (
                            <React.Fragment>Run</React.Fragment>
                          )
                        }
                      </Button>
                    </Grid>
                    <Grid className={classes.historicalDataContainer}>
                      {
                        this.state.historicalDataExists ? (
                          <React.Fragment>
                            <Button 
                              onClick={this.handleDownloadHistoricalClick} 
                              color="primary" 
                              className={[classes.historicalDataButton, isModalEditable ? classes.historicalDataButtonDisabled: ''].join(' ')}
                              disabled={this.state.dailyCalculationLoading || this.state.longTermCalculationLoading || this.state.taskInfo.taskExists}
                            >
                              Download historical data
                            </Button>
                            <Button 
                              onClick={() => { this.setHistoricalConfirmVisibility(true)}} 
                              color="primary" 
                              variant="contained" 
                              className={[classes.historicalDataButton, (isModalEditable || this.state.historicalDataDeletingConfirmOpened) ? classes.historicalDataButtonDisabled: ''].join(' ')}
                              disabled={isModalEditable || this.state.historicalDataDeletingConfirmOpened}
                            >
                              Delete historical data
                            </Button>
                          </React.Fragment>
                        ) : (
                          <div className={classes.historicalDataMessage}>
                            <p>There is no historical data</p>
                          </div>
                        )
                      }
                    </Grid>
                    { this.state.historicalDataDeletingConfirmOpened && (
                      <Grid className={classes.historicalDataContainerConfirmDeleting}>
                        <div>Historical data will be deleted!</div>
                        <div>
                          <Button
                            onClick={() => {this.setHistoricalConfirmVisibility(false)}}
                            color="primary" 
                            variant="contained"
                            className={[classes.historicalDataButton, classes.deleteHistoricalConfirmButton].join(' ')}
                          >Cancel</Button>
                          <Button
                            onClick={this.handleDeleteHistoricalClick} 
                            color="primary" 
                            variant="contained"
                            className={[classes.historicalDataButton, classes.deleteHistoricalConfirmButton].join(' ')}
                          >Confirm</Button>
                        </div>
                      </Grid>
                    )}
                </React.Fragment>
              )
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleModalClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {this.renderModal()}
        <Grid className={classes.mainContainer}>
          <Menu handleExportClick={this.handleExportClick}/>
          {this.renderBacktestOverviewTable()}
        </Grid>
        {this.renderBacktestCalculationInfoModal()}
        {this.renderStopCalculationModal()}
      </React.Fragment>
    );
  }
}

export default withStyles({
  ...styles(theme),
  ...backtestOverviewSyles(theme)
})(BacktestOverview);