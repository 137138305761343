export default (theme) => ({
  topHead: {
    height: 10,
    verticalAlign: 'bottom',
    '&>th': {
      fontFamily: 'Avenir',
      fontSize: 13,
      fontWeight: 'bold',
      color: '#9EA1AF',
      paddingBottom: 0
    },
    '& > th:first-child': {
      paddingLeft: 30
    },
    '& > th:last-child': {
      paddingLeft: 30,
    }
  },
  bottomHead: {
    height: 10,
    '& > th:first-child': {
      paddingLeft: 30
    },
    '&>th': {
      fontFamily: 'Avenir-Roman',
      fontSize: 13,
      color: '#9EA1AF',
    }
  },
  chooseBacktestButton: {
    height: 30,
    width: 157,
    fontFamily: 'Avenir',
    fontSize: 14,
  },
  tableHeader: {
    borderBottom: '2px solid #EDEDED',
  },
  topSectionHeading: {
    paddingBottom: '15px !important'
  },
  loaderIndicatorRow: {
    height: 250
  },
  circularContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  backtestDetailRow: {
    '&:nth-child(2n-1)': {
      backgroundColor: '#F8F8F8'
    },
    '& > td:first-child': {
      paddingLeft: 30,
      width: 300
    },
    '& > td:last-child': {
      paddingLeft: 30,
      width: 300
    },
    '& > td': {
      fontFamily: 'Avenir-Roman',
      fontSize: 16,
    },
    '& > td > img': {
      marginLeft: 5,
      marginBottom: 2
    }
  },
  backtestHeading: {
    fontFamily: 'Avenir',
    fontSize: 16,
    whiteSpace: 'nowrap'
  },
  calculatedDate: {
    fontFamily: "'Avenir-Roman' !important",
    fontSize: '13px !important',
    color: '#9EA1AF'
  },
  calculationErrorText: {
    color: 'red'
  },
  calculatingStatusText: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: 'Avenir',
    fontWeight: 'bold',
  },
  displayOnHover: {
    whiteSpace: 'nowrap',
    '& > span': {
      transition: 'all',
      transitionDuration: '0.2s',
      display: 'none'
    },
    '&:hover > span': {
      display: 'inline'
    }
  }
})