export default () => ({
  fundNameColumn: {
    fontFamily: 'Avenir',
    fontSize: 16,
    fontWeight: 'bold'
  },
  topHeading: {
    height: 10,
    verticalAlign: 'bottom',
    '&>td': {
      padding: '0px 5px',
      fontFamily: 'Avenir',
      fontSize: 13,
      fontWeight: 'bold',
      color: '#9EA1AF',
      height: 10
    },
    '& >td:first-child': {
      paddingLeft: 30
    }
  },
  bottomHeading: {
    verticalAlign: 'bottom',
    height: 10,
    '&>td': {
      padding: '0px 5px',
      fontFamily: 'Avenir-Roman',
      fontSize: 13,
      color: '#9EA1AF',
      height: 10
    },
  },
  fundValues: {
    '&>td': {
      padding: '0px 5px',
      fontFamily: 'Avenir-Roman',
      fontSize: 16,
      color: '#504F53'
    },
    '& >td:first-child': {
      paddingLeft: 30
    },
    '& > td > img': {
      marginLeft: 5,
      marginBottom: 2
    }
  },
  additionalInfoColumns: {
    height: 40,
    verticalAlign: 'top',
    '& > td': {
      padding: '0px 5px',
      fontFamily: 'Avenir-Roman',
      fontSize: 13,
      color: '#9EA1AF'
    },
    '& >td:first-child': {
      paddingLeft: 30
    }
  },
  fundTradeButton: {
    padding: 0,
    height: 30,
    width: 100,
    fontFamily: 'Avenir',
    fontSize: 14,
    marginBottom: 20
  },
  greyBackground: {
    backgroundColor: '#F8F8F8'
  },
  fundNameHeading: {
    '& >td:first-child': {
      paddingLeft: 30
    }
  },
  displayOnHover: {
    whiteSpace: 'nowrap',
    '& > span': {
      transition: 'all',
      transitionDuration: '0.2s',
      display: 'none'
    },
    '&:hover > span': {
      display: 'inline'
    }
  },
  calculationErrorText: {
    color: 'red'
  }
});