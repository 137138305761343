import React from 'react';
import _ from 'lodash';

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';
import Menu from '../../components/FundOverview/Menu';
import FundTable from '../../components/FundOverview/Table';
import BacktestsModal from '../../components/FundOverview/Backtest/Modal';
import BacktestsTable from '../../components/FundOverview/Backtest/Table';
import { BackendReource } from '../../api/index_v2';
import EvergreenWebSocket from '../../api/websocket';
import { CALCULATION_MESSAGE_TYPE, BACKTEST_STATUS } from '../BacktestOverview/constants';

class FundOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      backtests: undefined,
      funds: undefined,
      open: false,
      dailyBacktests: [],
      dailyBacktestsData: {
        loading: false,
        backtests: undefined
      },

      socket: undefined
    };

    this.fetchFunds = this.fetchFunds.bind(this);
    this.fetchBacktests = this.fetchBacktests.bind(this);
    this.fetchDailyBacktests = this.fetchDailyBacktests.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleDailyBacktestsChanged = this.handleDailyBacktestsChanged.bind(this);
    this.changeBacktestData = this.changeBacktestData.bind(this);
    this.changeBacktestProgress = this.changeBacktestProgress.bind(this);
    this.changeBacktestStatus = this.changeBacktestStatus.bind(this);
    this.updateFundRecomendationData = this.updateFundRecomendationData.bind(this);
    this.updateFundBacktestStatus = this.updateFundBacktestStatus.bind(this)
;  }

  componentWillMount() {
    const socket = new EvergreenWebSocket(`${window.location.host}/socket/`);
    socket.connect();
    socket.onMessage((message) => {
      const data = JSON.parse(message.data);
      
      switch(data.type) {
        case CALCULATION_MESSAGE_TYPE.START:
          this.changeBacktestStatus(data.backtest, BACKTEST_STATUS.IN_PROGRESS);
          break;
        case CALCULATION_MESSAGE_TYPE.PROGRESS:
          this.changeBacktestProgress(data.backtest, data.progress, data.message);
          break;
        case CALCULATION_MESSAGE_TYPE.DONE:
          this.changeBacktestData(JSON.parse(_.isString(data.backtest) ? data.backtest.replace(/\bNaN\b/g, "null") : data.backtest)['bt_id'], data.backtest);
          this.updateFundRecomendationData(JSON.parse(_.isString(data.backtest) ? data.backtest.replace(/\bNaN\b/g, "null") : data.backtest)['bt_id']);
          break;
        case CALCULATION_MESSAGE_TYPE.FAIL:
          this.changeBacktestStatus(data.backtest, BACKTEST_STATUS.FAILURE);
          this.updateFundBacktestStatus(data.backtest, BACKTEST_STATUS.FAILURE);
          break;
        case CALCULATION_MESSAGE_TYPE.NEW:
          this.changeBacktestStatus(data.backtest, BACKTEST_STATUS.IN_QUEUE);
          break;
        default: 
          break;
      }
    });

    this.setState({
      socket
    })
  }

  componentDidMount() {
    let dailyBacktests = JSON.parse(localStorage.getItem('dailyBacktests'));
    this.setState({
      dailyBacktests: dailyBacktests ? dailyBacktests : []
    });
    if (dailyBacktests) {
      setTimeout(() => this.fetchDailyBacktests(), 0);
    }
    this.fetchFunds()
  }

  componentWillUnmount() {
    const { socket } = this.state;

    socket.close();
  }

  fetchFunds() {
    BackendReource.at('fund/').get().then(response => {
      this.setState({
        funds: _.isString(response.data) ? JSON.parse(response.data.replace(/\bNaN\b/g, "null")) : response.data,
        loading: false
      });
    })
  }

  fetchBacktests() {
    BackendReource.at('backtest_overview/').get().then(response => {
      this.setState({
        backtests: (_.isString(response.data) ? JSON.parse(response.data.replace(/\bNaN\b/g, "null")) : response.data).map((value) => {
          return {
            id: value.bt_id,
            name: value.bt_name
          }
        })
      });
    });
  }

  fetchDailyBacktests() {
    this.handleModalClose();

    this.setState({
      dailyBacktestsData: {
        loading: true,
        backtests: undefined
      }
    });

    if (this.state.dailyBacktests.length > 0) {
      BackendReource.at('backtests/daily/').get({
        backtest_id: this.state.dailyBacktests.join(',')
      }).then(response => {
        this.setState({
          dailyBacktestsData: {
            loading: false,
            backtests: _.isString(response.data) ? JSON.parse(response.data.replace(/\bNaN\b/g, "null")) : response.data
          }
        })
      });
    }
    else {
      this.setState({
        dailyBacktestsData: {
          loading: false
        }
      });
  
    }
  }

  handleModalOpen() {
    this.setState({
      open: true
    });
    this.fetchBacktests();
  }

  handleModalClose() {
    this.setState({
      backtests: undefined,
      open: false
    })
  }

  handleDailyBacktestsChanged(dailyBacktests) {
    this.setState({
      dailyBacktests
    });
    setTimeout(() => {
      localStorage.setItem('dailyBacktests', JSON.stringify(this.state.dailyBacktests));
    }, 0);
  }

  changeBacktestStatus(backtestId, status) {
    let backtests = this.state.dailyBacktestsData.backtests;

    let setStatus = (backtest, status) => {
      if (backtest.calculation) {
        backtest.calculation.status = status;
      }
      else {
        backtest.calculation = {
          status
        }
      }
    }

    for(let i = 0; i < backtests.length; i++) {
      if (backtests[i].bt_id === parseInt(backtestId))  {
        switch (status) {
          case BACKTEST_STATUS.IN_QUEUE:
            if (!backtests[i].calculation || (backtests[i].calculation && backtests[i].calculation.status !== BACKTEST_STATUS.IN_PROGRESS)) {
              setStatus(backtests[i], status);
            }
            break;
          default:
            setStatus(backtests[i], status);
            break;
        }
      }
    }

    this.setState({
      dailyBacktestsData: {
        ...this.state.dailyBacktestsData,
        backtests
      }
    });
  }

  changeBacktestProgress(backtestId, progress, message) {
    let backtests = this.state.dailyBacktestsData.backtests;

    for(let i = 0; i < backtests.length; i++) {
      if (backtests[i].bt_id === parseInt(backtestId))  {
        backtests[i].calculation.progress = parseFloat(progress);
        backtests[i].calculation.message = message;
      }
    }

    this.setState({
      dailyBacktestsData: {
        ...this.state.dailyBacktestsData,
        backtests
      }
    });
  }

  changeBacktestData(backtestId, data) {
    let backtests = this.state.dailyBacktestsData.backtests;

    for(let i = 0; i < backtests.length; i++) {
      if (backtests[i].bt_id === backtestId)  {
        backtests[i] = JSON.parse(_.isString(data) ? data.replace(/\bNaN\b/g, "null") : data);
      }
    }

    this.setState({
      dailyBacktestsData: {
        ...this.state.dailyBacktestsData,
        backtests
      }
    });
  }

  updateFundRecomendationData(backtestId) {
    let { funds } = this.state;

    const fund = _.find(funds, (value) => {
      return +value.backtest.bt_id === +backtestId;
    });

    if (fund) {
      BackendReource.at(`fund/${fund.id}/`).get().then(response => {
        for(let i = 0; i < funds.length; i++) {
          if (funds[i].id === fund.id) {
            let data = _.isString(response.data) ? JSON.parse(response.data.replace(/\bNaN\b/g, "null")) : response.data;
            funds[i] = data;
            break;
          }
        }
        this.setState({
          funds: funds
        });
      });
    }
  }

  updateFundBacktestStatus(backtestId, status) {
    const { funds } = this.state;

    const fund = _.find(funds, (value) => {
      return +value.backtest.bt_id === +backtestId;
    });

    if (fund) {
      for(let i = 0; i < funds.length; i++) {
        if (funds[i].id === fund.id) {
          funds[i].backtest.calculation.status = status
          break;
        }
      }
    }

    this.setState({
      funds
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.mainContainer}>
        <Menu />
        {this.state.loading ? (
          <Grid className={classes.circularProgress}>
            <CircularProgress  size={30}/>
          </Grid>
        ) : (
          <Paper className={classes.tableContainer}>
            <FundTable funds={this.state.funds} history={this.props.history}/>
            <BacktestsTable
              openBacktestsModal={this.handleModalOpen}
              loading={this.state.dailyBacktestsData.loading}
              backtests={this.state.dailyBacktestsData.backtests}
            />
            <BacktestsModal
              open={this.state.open}
              backtests={this.state.backtests}
              closeModal={this.handleModalClose}
              dailyBacktests={this.state.dailyBacktests}
              handleDailyBacktestsChanged={this.handleDailyBacktestsChanged}
              fetchDaiyBacktests={this.fetchDailyBacktests}
            />
          </Paper>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(FundOverview);