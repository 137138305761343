import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import { Link, NavLink } from 'react-router-dom';

import styles from './styles';
import images from '../../images';
import { AccountsResource } from '../../api';
import { logout } from '../../containers/Authentication/actions';


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.menuClose = this.menuClose.bind(this);
    this.onLogoutClicked = this.onLogoutClicked.bind(this);
  }

  toggleMenu(event) {
    this.setState({
      anchorEl: event.currentTarget
    });
  }

  menuClose() {
    this.setState({
      anchorEl: null
    });
  }

  onLogoutClicked() {
    const { history, dispatch } = this.props;

    AccountsResource.at('logout/').post().then((response) => {
      dispatch(logout());
      history.push('/login');
      this.menuClose();
    });
  }

  render() {
    const { classes, authentication: { user } } = this.props;
    const { anchorEl } = this.state;
    let open = Boolean(anchorEl);

    return (
      <React.Fragment>
        { user && (
          <div className={classes.root}>
            <AppBar position="static" color="inherit">
              <Toolbar className={classes.headerContainer}>
                <Link to="/">
                  <img src={images.mainLogo} alt="Evergreen" className={classes.headerLogo}/>
                </Link>
                <Grid className={classes.navContainer}>
                  <Button
                    disableRipple
                    className={classes.navButton}
                    component={
                      (props) => <NavLink exact to="/" activeClassName={classes.navButtonActive} {...props}/>
                    }
                  >
                    Fund Overview
                  </Button>
                  <Button
                    disableRipple
                    className={classes.navButton}
                    component={
                      (props) => <NavLink to="/fund-trading" activeClassName={classes.navButtonActive} {...props}/>
                    }
                  >
                    Fund Trading
                  </Button>
                  <Button
                    disableRipple
                    className={classes.navButton}
                    component={
                      (props) => <NavLink exact to="/backtest-overview" activeClassName={classes.navButtonActive} {...props}/>
                    }
                  >
                    Backtest Overview
                  </Button>
                  <Button
                    disableRipple
                    className={classes.navButton}
                    component={
                      (props) => <NavLink exact to="/trade-sheets" activeClassName={classes.navButtonActive} {...props}/>
                    }
                  >
                    Trade Sheets
                  </Button>
                </Grid>
                <Grid className={classes.userContainer}>
                  <Button
                    className={classes.userButton}
                    disableRipple
                    onClick={this.toggleMenu}
                  >
                    <div className={classes.userInfo}>
                      <span className={classes.userHello}>Welcome,</span>
                      <span className={classes.userName}>{`${user.first_name} ${user.last_name}`}</span>
                    </div>
                    <img src={images.icons.user} alt=""/>
                  </Button>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    style={{
                      position: 'absolute',
                      left: 0,
                      top: 0
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={this.menuClose}
                  >
                    <MenuItem disableRipple onClick={this.onLogoutClicked} className={classes.menuItem}>
                      <PowerSettingsNew className={classes.menuItemIcon}/>  Logout
                    </MenuItem>
                  </Menu>
                </Grid>
              </Toolbar>
            </AppBar>
          </div>
        )
      }
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authentication: state.get('authentication').toJS(),
});

export default compose(withRouter)(connect(mapStateToProps)(withStyles(styles)(Header)));
