import React from 'react';

import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import styles from './styles';
import FuturesItem from '../Item/futures';
import BondsCashItem from '../Item/additionalBonds';

export default withStyles(styles)((props) => {
  const { classes, fund, } = props;

  const renderFutureHeading = (sectionName) => {
    const { classes } = props;

    return (
      <TableHead>
        <TableRow className={[classes.topHeading, classes.futuresHeading].join(' ')} style={{verticalAlign: "bottom", height: 10}}>
          <TableCell rowSpan={2} style={{paddingBottom: 10}}>{sectionName} <br />Futures</TableCell>
          <TableCell rowSpan={2} style={{paddingBottom: 10}}>Contract</TableCell>
          <TableCell rowSpan={2} style={{paddingBottom: 10}}>Price</TableCell>
          <TableCell rowSpan={2} style={{paddingBottom: 10}}>Excahnge rate <br />from EUR</TableCell>
          <TableCell rowSpan={2} style={{width: 20, paddingBottom: 10}}>Fund Is <br />Contr./%</TableCell>
          <TableCell rowSpan={2} style={{width: 20, paddingBottom: 10}}>Pending <br />prev. day/intraday</TableCell>
          <TableCell rowSpan={2} style={{paddingBottom: 10}}>BT Should <br />%(Change)</TableCell>
          <TableCell rowSpan={2} style={{paddingBottom: 10}}>BT Rec. <br />Contr.</TableCell>
          <TableCell rowSpan={2} style={{paddingBottom: 10}}>Trade <br />Result%</TableCell>
          <TableCell rowSpan={2} style={{paddingBottom: 10}}>Modify <br />Contr.</TableCell>
          <TableCell colSpan={3} />
        </TableRow>
        </TableHead>
    )
  };
  return (
    <Grid className={classes.tableContainer} style={{padding: 0, paddingTop: 25}}>
      <Table className={classes.futuresTable} style={{minWidth: 1250}}>
        {renderFutureHeading('Stock')}
        <TableBody>
          {fund.stocks_futures.map((value) => {
            return <FuturesItem handleModifyContrChange={props.handleModifyContrChange} 
                                future={value} 
                                handleBasketApprove={props.handleBasketApprove}
                                handleBasketExecute={props.handleBasketExecute} 
                                handleBasketReset={props.handleBasketReset}/>
          })}
        </TableBody>
        {renderFutureHeading('Bond')}
        <TableBody>
          {fund.bonds_futures.map((value) => {
            return <FuturesItem future={value} 
                                handleModifyContrChange={props.handleModifyContrChange} 
                                handleBasketApprove={props.handleBasketApprove} 
                                handleBasketExecute={props.handleBasketExecute} 
                                handleBasketReset={props.handleBasketReset}/>
          })}
        </TableBody>
      </Table>
      <Table className={classes.futuresTable} style={{marginTop: 30, minWidth: 1250}}>
        <TableHead>
          <TableRow className={[classes.topHeading, classes.futuresHeading].join(' ')}>
            <TableCell style={{width: 163}}>Bonds/Cash</TableCell>
            <TableCell style={{width: 83}}>ISIN</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Fund Is Contr./%</TableCell>
            <TableCell style={{width: 20}}>Maturity</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell colSpan={3}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fund.additional_bonds.map((value) => {
            return <BondsCashItem future={value}/>
          })}
        </TableBody>
      </Table>
    </Grid>
  );
})