import React from 'react';

import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import styles from './styles';
import fundOverviewStyles from '../../FundOverview/Item/styles';
import {formatToGerman, formatDate } from '../../../utils/backtestOverviewUtils';

export default withStyles({...styles(), ...fundOverviewStyles()})((props) => {
  const { classes, future} = props;
  return (
    <React.Fragment>
      <TableRow className={[classes.fundValues].join(' ')} style={{verticalAlign: 'top'}}>
        <TableCell style={{paddingTop: 17, width: 200}}><b style={{whiteSpace: "nowrap"}}>{future.desc}</b></TableCell>
        <TableCell style={{paddingTop: 17}}>{future.isin}</TableCell>
        <TableCell style={{paddingTop: 17, width: 100}}>{formatToGerman(future.price)}</TableCell>
        <TableCell style={{paddingTop: 17, width: 200}}>{formatToGerman(future.amount)}/{formatToGerman(future.weight_in_perc)}%</TableCell>
        <TableCell style={{paddingTop: 17, width: 100}}>{formatDate(future.maturity)}</TableCell>
        <TableCell style={{paddingTop: 17, width: 100}}>{formatToGerman(future.duration)}</TableCell>
        <TableCell style={{paddingTop: 17, width: 100}}>{formatToGerman(future.currency)}</TableCell>
        <TableCell colSpan={3}></TableCell>
      </TableRow>
    </React.Fragment>
  )
})