export default (theme) => ({
  modalSectionTitle: {
    color: '#9F3A7E',
    fontSize: 25,
    fontFamily: 'Avenir',
    fontWeight: 'bold',
    marginBottom: 35
  },
  modelaSectionFieldset: {
    display: 'flex',
    alignItems: 'center'
  },
  modalSection: {
    '& $formControl': {
      marginRight: 20
    }
  },
  formControl: {},
  runButton: {
    marginTop: 6,
    height: 30,
    fontSize: 14,
    ontFamily: 'Avenir',
    fontWeight: 'bold',
  },
  circularProgressButton: {
    color: 'white',
    width: '20px !important',
    height: '20px !important'
  },
  modalLoaderContainer: {
    height: 348,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  historicalDataContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between'
  },
  historicalDataMessage: {
    padding: 20,
    backgroundColor: "#FAFAFA",
    width: '100%',
    '& p': {
      margin: 0,
      fontSize: 18,
      fontFamily: 'Avenir',
      color: '#504F53'
    }
  },
  historicalDataButton: {
    width: '49%',
    border: `2px solid ${theme.palette.primary.main}`,
    fontSize: 14,
    fontFamily: 'Avenir',
    fontWeight: 'bold',
    minHeight: '40px !important',
    padding: '0px 15px',
    '&$disabled': {
      border: `2px solid red`
    }
  },
  historicalDataButtonDisabled: {
    border: `2px solid #EDEDED`
  },
  historicalDataContainerConfirmDeleting: {
    padding: '10px 0',
    backgroundColor: '#fafafa',
    marginTop: 10,
    '& > div': {
      display: 'flex',
      justifyContent: 'center'
    },
    '& > div:first-child': {
      fontSize: 18,
      fontFamily: 'Avenir',
      color: '#504F53',
      marginBottom: 10
    }
  },
  deleteHistoricalConfirmButton: {
    width: 'auto',
    margin: '0 10px'
  },
  modalTitle: {
    '& > h6': {
      color: '#9F3A7E',
      fontSize: 25,
      fontFamily: 'Avenir'
    }
  },
  stopCaclulationModalContent: {
    color: '#504F53'
  }
})