import React from 'react';

import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { SortableContainer, arrayMove } from 'react-sortable-hoc';

import BacktestItem from '../Item';
import styles from '../styles';
import { 
  getBacktestItemOrdering, 
  saveBacktestItemOrdering,
  sortBacktestItems
} from '../../../utils/backtestOverviewUtils';

const TableBodySortable = SortableContainer(({children, classes}) => (
  <TableBody className={classes.tableBody}>
    {children}
  </TableBody>
));
TableBodySortable.muiName = 'TableBody';

export default withStyles(styles)(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }

    this.renderItems = this.renderItems.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.sortBacktestOverview = this.sortBacktestOverview.bind(this);
  }

  componentDidMount() {
    this.sortBacktestOverview();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.backtestsOverview.length === 0 && this.props.backtestsOverview.length > 0) {
      this.sortBacktestOverview();
    }
  }

  sortBacktestOverview() {
    let backtestItemsOrdering = getBacktestItemOrdering();
    if (!backtestItemsOrdering) {
      saveBacktestItemOrdering(this.props.backtestsOverview);
    }
    else {
      let array = this.props.backtestsOverview;
      this.props.handleSortAction(sortBacktestItems(array));
    }
  }

  renderItems() {
    return this.props.backtestsOverview.map((backtestOverview, index) => {
      return <BacktestItem 
        backtestOverview={backtestOverview}
        {...this.props}
        index={index}
        handleModalOpen={this.props.handleModalOpen}
        openErrorModal={this.props.openErrorModal}
        handleStopCalculationClick={this.props.handleStopCalculationClick}
      />
    });
  }

  onSortEnd = (options) => {
    let array = this.props.backtestsOverview;
    this.props.handleSortAction(arrayMove(array, options.oldIndex, options.newIndex));
    saveBacktestItemOrdering(this.props.backtestsOverview);
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.alignBottom}>
              <TableCell rowSpan={2} className={classes.tableCellPadding} style={{paddingLeft: 38}}>Backtests</TableCell>
              <TableCell rowSpan={2} className={classes.tableCellPadding} style={{width: 125}}>Calculation <br /> Date</TableCell>
              <TableCell colSpan={3}>Performance</TableCell>
              <TableCell colSpan={3}>Weight%</TableCell>
              <TableCell rowSpan={2} className={classes.tableCellPadding}>Risk Budget</TableCell>
              <TableCell rowSpan={2} className={classes.tableCellPadding}>LPM%</TableCell>
              <TableCell colSpan={2}>Volatility</TableCell>
              <TableCell rowSpan={2} className={classes.tableCellPadding}>VAR%</TableCell>
              <TableCell rowSpan={2}></TableCell>
            </TableRow>
            <TableRow className={[classes.alignTop, classes.tableHeadCellHelp].join(' ')}>
              <TableCell>1Day</TableCell>
              <TableCell>YTD</TableCell>
              <TableCell>P.A.</TableCell>
              <TableCell>w_eq</TableCell>
              <TableCell>w_fi</TableCell>
              <TableCell>w_csh</TableCell>
              <TableCell>ex ante</TableCell>
              <TableCell>ex post</TableCell>
            </TableRow>
          </TableHead>
          <TableBodySortable 
            lockAxis="y"
            transitionDuration={200}
            onSortEnd={this.onSortEnd} 
            useDragHandle 
            {...this.props}
          >
            { this.renderItems() }
          </TableBodySortable>
        </Table>
      </Paper>
    )
  }
})