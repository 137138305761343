import React from 'react';

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import BacktestForm from '../Form';
import styles from '../styles';

export default withStyles(styles)(class extends React.Component {
  render() {
    const { classes, backtest, errors } = this.props;

    return (
      <React.Fragment>
        <Paper className={classes.formContainer}>
          <Grid className={classes.headerContainer}>
            <h2>{this.props.label}</h2>
          </Grid>
          <Grid className={classes.form}>
            <BacktestForm
              classes={classes}
              backtest={backtest}
              errors={errors}
              editable={this.props.editable}
              handleInputChange={this.props.handleInputChange}
            />
          </Grid>
          <Grid className={classes.buttonContainer}>
            <Button 
              variant="contained" 
              color="primary"
              className={classes.submitButton}
              onClick={this.props.handleSaveNew}
              disabled={!this.props.editable}
            >
              Save as new backtest
            </Button>
            <Button 
              variant="contained" 
              color="primary"
              className={classes.submitButton}
              onClick={this.props.handleSave}
              disabled={!this.props.editable}
            >
              Save changes
            </Button>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
});