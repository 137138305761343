class EvergreenWebSocket {
    constructor(path) {
        this._path = path
        this._connection = undefined;
    }

    connect() {
        this._connection = new WebSocket(`${window.location.protocol === "https:" ? "wss:" : "ws:"}//${this._path}`);
        this._connection.onclose = () => {
            setTimeout(() => this.connect(), 500);
        }
    }

    onMessage(callback) {
        this._connection.onmessage = callback
    }

    close() {
        this._connection.onclose = () => {}
        this._connection.close();
    }
}

export default EvergreenWebSocket;