/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import mainTheme from '../../themes/mainTheme';

import LoginPage from '../../containers/Authentication/Login/Loadable';
import ForgotPasswordPage from '../../containers/Authentication/ForgotPassword/Loadable';
import Notification from '../../containers/Authentication/Notification/Loadable';
import ResetPasswordPage from '../../containers/Authentication/ResetPassword/Loadable';
import { ActivateAccount } from '../../containers/Authentication/ResetPassword';
import FundOverview from '../../containers/FundOverview';
import FundTrading from '../../containers/FundTrading';
import BacktestOverview from '../../containers/BacktestOverview';
import TradeSheets from '../../containers/TradeSheets';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import EditBacktest from '../../containers/Backtest/Edit';
import CreateBacktest from '../../containers/Backtest/Create';
import PrivateRoute from '../../components/PrivateRoute';

import { PERMISSION_TYPE } from '../../components/PrivateRoute/constants';

export default function App() {
  return (
    <MuiThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Helmet
        titleTemplate="Violet"
        defaultTitle="Violet"
      >
        <meta name="description" content="Basic App" />
      </Helmet>
      <Header />
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route exact path="/account/reset/:uid/:token" component={ResetPasswordPage} />
        <Route path="/account/reset" component={ForgotPasswordPage} />
        <Route path="/account/reset-done" component={Notification} />
        <Route exact path="/account/activate/:uid/:token" component={ActivateAccount} />
        <PrivateRoute authenticationType={PERMISSION_TYPE.AUTHORIZED_ONLY} exact path="/" component={FundOverview} />
        <PrivateRoute authenticationType={PERMISSION_TYPE.AUTHORIZED_ONLY} exact path="/fund-trading/" component={FundTrading} />
        <PrivateRoute authenticationType={PERMISSION_TYPE.AUTHORIZED_ONLY} path="/fund-trading/:id" component={FundTrading} />
        <PrivateRoute authenticationType={PERMISSION_TYPE.AUTHORIZED_ONLY} path="/backtest-overview" component={BacktestOverview} />
        <PrivateRoute authenticationType={PERMISSION_TYPE.AUTHORIZED_ONLY} path="/backtest/edit/:id" exact component={EditBacktest} />
        <PrivateRoute authenticationType={PERMISSION_TYPE.AUTHORIZED_ONLY} path="/backtest/create" exact component={CreateBacktest} />
        <PrivateRoute authenticationType={PERMISSION_TYPE.AUTHORIZED_ONLY} path="/trade-sheets" exact component={TradeSheets} />
      </Switch>
      <Footer />
    </MuiThemeProvider>
  );
}
