import { 
  LOGIN_SUCCESSFUL,
  LOGOUT_SUCCESSFUL 
} from "./constants";

/**Save user in global store after successful login 
 * @param {Object} user Object, that contain user data
*/
export function login(user) {
  return {
    type: LOGIN_SUCCESSFUL,
    user
  };
}

/**Remove user data from global store after logout*/
export function logout() {
  return {
    type: LOGOUT_SUCCESSFUL
  };
}