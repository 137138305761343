import qs from 'qs';
import axios from 'axios';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

const axiosInstance = axios.create({
  baseURL: '/api/v2/',
  paramsSerializer: (params) => (
    qs.stringify(params, { arrayFormat: 'repeat' })
  ),
});

class Resource {
  constructor(resourceUrl) {
    this.config = {
      withCredentials: true
    };
    this.resourceUrl = resourceUrl;
  }

  _send(data, method) {
    if (data) this.config.data = data;
    this.config.method = method;
    return this.request();
  }

  at(url) {
    const newResourceUrl = `${this.resourceUrl}${url}`;
    return new Resource(newResourceUrl);
  }

  get(params) {
    if (params) this.config.params = params;
    this.config.method = 'GET';
    return this.request();
  }

  post(data) {
    return this._send(data, 'POST');
  }

  put(data) {
    return this._send(data, 'PUT');
  }

  delete(data) {
    return this._send(data, 'DELETE');
  }

  patch(data) {
    return this._send(data, 'PATCH');
  }

  request() {
    this.config.url = this.resourceUrl;
    return axiosInstance.request(this.config)
      .then((response) => {
        this.config = {
          withCredentials: true
        };
        return response;
      })
      .catch((error) => {
        this.config = {
          withCredentials: true
        };
        throw (error.response && error.response.data) || error.message;
      });
  }
}

export const AccountsResource = new Resource('accounts/');
export const BackendReource = new Resource('evergreen_backend/');
