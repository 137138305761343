import React from 'react';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import {} from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';

import styles from '../styles';
import images from '../../../images';
import { AccountsResource } from '../../../api';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    const { match: { params } } = this.props;

    this.state = {
      password: '',
      confirmPassword: '',
      uid: params.uid,
      token: params.token,
      errors: [],
      isReset: false
    };

    this.submitUrl = props.activationMode ? 'activate/' : 'reset/';
    this.validateUrl = `${this.submitUrl}validate/`;


    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setErrors = this.setErrors.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;

    const payload = {
      uid: this.state.uid,
      token: this.state.token,
    };

    AccountsResource.at(this.validateUrl).post(payload)
      .then()
      .catch( () => history.push(`/not-found/`) );
  }

  handleSubmit(event) {
    event.preventDefault();

    const { history } = this.props;

    const payload = {
      password1: this.state.password,
      password2: this.state.confirmPassword,
      uid: this.state.uid,
      token: this.state.token,
    };

    AccountsResource.at(this.submitUrl).post(payload)
      .then( () => {
        if (this.props.activationMode) {
          history.push(`/login/`)
        }
        else {
          this.setState({
            isReset: true
          });
        }
      }).catch( (e) => this.setState({errors: e}) );
  }

  handleInputChange(fieldName) {
    return function(event) {
      this.setState({
        [fieldName]: event.target.value
      });
    }.bind(this);
  }

  setErrors(errors) {
    this.setState({
      errors
    });
  }

  render() {
    const { classes } = this.props;
    let { password, confirmPassword, errors } = this.state;

    return (
      <Grid className={classes.layer}>
      <Grid container className={classes.mainContainer}>
        <Grid item className={classes.mainLogoContainer}>
          <img src={images.mainLogo} alt="Evergreen"/>
        </Grid>
        <Grid item className={classes.supportTextContainer}>
          {this.state.isReset ? (
            <p style={{margin: 0, marginBottom: 45}}>
              Your password has been reset.
            </p>
          ) : (
            <React.Fragment>
              <p style={{marginBottom: 5}}>
                Let's set up a new password.
              </p>
              <p style={{margin: 0, marginBottom: 45}}>
                Your password must contain at least 8 characters.
              </p>
            </React.Fragment>
          ) } 
        </Grid>
        <Grid item className={classes.mainFormContainer}>
          <form 
            autoComplete="off" 
            onSubmit={this.handleSubmit}
            className={classes.mainForm}
          >
            {!this.state.isReset && (
              <React.Fragment>
                <FormControl className={classes.formControl}>
                  <TextField 
                    error={'password1' in errors}
                    label={'New password'}
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={this.handleInputChange('password')}
                    helperText={'password1' in errors ? errors['password1'] : ''}
                    InputLabelProps={{
                      filled: true
                    }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField 
                    error={'password2' in errors}
                    label={'Confirm your new password'}
                    variant="outlined"
                    type="password"
                    value={confirmPassword}
                    onChange={this.handleInputChange('confirmPassword')}
                    helperText={'password2' in errors ? errors['password2'] : ''}
                    InputLabelProps={{
                      filled: true
                    }}
                  />
                </FormControl>
              </React.Fragment>
            )}
            
            <FormControl className={classes.formControl}>
              {!this.state.isReset ? (
                <Button 
                  variant="contained" 
                  type="submit"
                  color="primary"
                  className={classes.submitButton}
                >
                  Save my password
                </Button>
              ) : (
                <Link to="/login" className={classes.forgotPasswordLink}>
                  <span className={classes.linkArrow}>&#x3c;</span>
                  <span className={classes.linkText}>Back to Sign In</span>
                </Link>
              )}
            </FormControl>
          </form>
        </Grid>
      </Grid>
      </Grid>
    )
  }
};


const SetPasswordView = withStyles(styles)(ResetPassword)

export default SetPasswordView;

export const ActivateAccount = (props) => (
  <SetPasswordView  activationMode={true}  {...props}/>
)