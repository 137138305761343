import React from 'react';

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import BacktestCreateComponent from '../../../components/Backtest/Create';
import styles from '../styles';
import { BackendReource } from '../../../api/index_v2';
import backtestValidationSchema from '../../../components/Backtest/Form/schema';
import Snackbar from '../../../components/Snackbar';
import {SAVE_BACKTEST_TYPE} from "./constants";

const defaultBacktest = {
    "bal_1_eq": 0.3,
    "bal_1_fi": 0.7,
    "bal_2_eq": 0.5,
    "bal_2_fi": 0.5,
    "bal_3_eq": 0.7,
    "bal_3_fi": 0.3,
    "basket_eq": 101,
    "basket_fi": 201,
    "backtest_id": 2,
    "desc": 'Stable Return',
    "basket_cash": 301,
    "cov_days_back": 260,
    "cov_kappa": 0.94,
    "cov_method": 2,
    "crash_corr": 1,
    "dividends_op": 0,
    "export_path": 'edit_path',
    "export_to_file": 1,
    "floor_bt": 0.91,
    "floor_cap_factor": 1,
    "floor_subs": 0.91,
    "hold_cap": 0,
    "hold_floor": 1,
    "leverage": 0.5,
    "lpm_risk": 1.64,
    "max_eq": 0.8,
    "max_fi": 1,
    "min_eq": 0,
    "min_fi": 0,
    "mngmt_fee": 0.005,
    "model_op": 1,
    "moving_cap_factor": 0.75,
    "moving_floor_factor": 0.5,
    "no_no_days_in_period": 260,
    "no_subs": 260,
    "opt_profile": 1,
    "ovnt_risk_csh": 0,
    "ovnt_risk_eq": 0.13,
    "ovnt_risk_fi": 0.025,
    "paths_mcsim": 5000,
    "perf_fee": 0,
    "randomization_op": 1,
    "row_def_id": 1,
    "sets_mcsim": 1,
    "trade_filter_eq": 0.035,
    "trade_filter_fi": 0.05,
    "trading_fee_eq": 0.0005,
    "trading_fee_fi": 0.0005,
};

export default withStyles(styles)(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: '',
      backtest: defaultBacktest,
      errors: [],
      isEditable: true,
      popupOpen: false,
      popupVariant: "",
      popupMessage: ""
    };

    this.renderBacktestEditComponent = this.renderBacktestEditComponent.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.toggleEditable = this.toggleEditable.bind(this);
    this.showPopup = this.showPopup.bind(this);
  }

  showPopup() {
    this.setState({
      popupOpen: true
    });
    setTimeout(() => {
      this.setState({
        popupOpen: false
      });
    }, 3000);
  }

  handleInputChange(fieldName) {
    return function(event) {
      let { value } = event.target;

      value = value ? value : null;

      this.setState({
        backtest: {
          ...this.state.backtest,
          [fieldName]: value
        }
      });
    }.bind(this);
  }

  handleSave = type => event => {
    const { backtest } = this.state;
    const { history } = this.props;

    this.toggleEditable();
    backtestValidationSchema.validate(backtest, {abortEarly: false}).then(() => {
      BackendReource.at(`backtests/create/`).post({...backtest}).then((response) => {
        switch(type) {
          case SAVE_BACKTEST_TYPE.SAVE:
            history.push(`/backtest-overview`);
            break;
          case SAVE_BACKTEST_TYPE.SAVE_AND_ADD_ANOTHER:
            this.setState({
              popupVariant: "success",
              popupMessage: "Backtest created.",
              backtest: defaultBacktest
            }
            );
            this.showPopup();
            break;
          default:
            break;
        }
        this.toggleEditable();
      }).catch((errors) => {
        this.setState({
          errors,
          popupVariant: "error",
          popupMessage: `${Object.keys(errors).length} error${Object.keys(errors).length > 1 ? 's' : ''} occurred.`
        });
        this.toggleEditable();
        this.showPopup();
      });
    }).catch((err) => {
      const errors = {};
      err.inner.map((error) => {
        errors[error.path] = error.message;
        return null;
      });
      this.setState({
        errors: errors,
        popupVariant: "error",
        popupMessage: `${Object.keys(errors).length} error${Object.keys(errors).length > 1 ? 's' : ''} occurred.`
      });
      this.toggleEditable();
      this.showPopup();
    });
  };

  toggleEditable() {
    this.setState({
      isEditable: !this.state.isEditable
    });
  }

  renderBacktestEditComponent() {
    const { classes } = this.props;

    if (this.state.backtest) {
      return <BacktestCreateComponent
        backtest={this.state.backtest}
        errors={this.state.errors} 
        handleInputChange={this.handleInputChange}
        handleSaveNew={this.handleSave}
        handleSave={this.handleSave}
        editable={this.state.isEditable}/>
    }
    return (
      <Grid className={classes.circularProgress}>
        <CircularProgress size={30} />
      </Grid>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Snackbar
          variant={this.state.popupVariant}
          message={this.state.popupMessage}
          snackbarOpen={this.state.popupOpen}
        />
        <Grid className={classes.mainContainer}>
          {this.renderBacktestEditComponent()}
        </Grid>
      </React.Fragment>
    )
  }
});