import React from 'react';

import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import FundItem from '../Item';
import styles from '../../BacktestOverview/styles';


export default withStyles(styles)(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }

    this.renderItems = this.renderItems.bind(this);
    this.handleToTrdaeClick = this.handleToTrdaeClick.bind(this);
  }

  renderItems() {
    if (this.props.funds) {
      return this.props.funds.map((value, index) => {
        return <FundItem nthChild={index % 2 !== 0} key={index} fund={value} toTrade={this.handleToTrdaeClick}/>
      });
    }
  }

  handleToTrdaeClick = id => event => {
    const { history } = this.props;

    history.push(`/fund-trading/${id}`);
  }

  render() {
    const { classes } = this.props;

    return (
      <Table className={classes.table} style={{minWidth: 1250, height:312}}>
        <TableBody style={{height: 312}}>
          { this.renderItems() }
        </TableBody>
      </Table>
    )
  }
});