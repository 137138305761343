import React, { Component } from 'react';

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import styles from './styles';

export default withStyles(styles)(class extends Component {
  constructor(props) {
    super(props);
    this.state = {}

    this.handleOnCloseClick = this.handleOnCloseClick.bind(this);
  }

  handleOnCloseClick() {
    this.props.closeModal();
  }

  render() {
    const { classes, open, backtest, closeModal } = this.props;

    return (
      <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>{`${backtest.bt_id} - ${backtest.bt_name}`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid className={classes.errorDetailsContainer}>
            <React.Fragment>
              {
                backtest.calculation.message && (
                  <p><b>Message: </b>{backtest.calculation.message}</p>
                )
              }
              {
                backtest.calculation.execution_time && (
                  <p>
                    <b>Execution time: </b>{Math.round(backtest.calculation.execution_time * 1000) / 1000} s
                  </p>
                )
              }
            </React.Fragment>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleOnCloseClick} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
    )
  }
})