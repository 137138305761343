import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../Table/styles';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import {formatDate, formatDateTimeToTime} from '../../../utils/backtestOverviewUtils';
import Grid from "@material-ui/core/Grid";


export default withStyles(styles)(class extends React.Component{
  constructor(props){
    super(props);
    this.state = {

    };

    this.tradeSheetExportClick = this.tradeSheetExportClick.bind(this)
  }

  tradeSheetExportClick(){
    const { handleExportTradeSheet, tradeSheet } = this.props;
    handleExportTradeSheet(tradeSheet.id)
  }

  render() {
    const {
      classes,
      tradeSheet,
    } = this.props;

    return (
      <React.Fragment>
        <TableRow className={classes.tableBodyRow}>
          <TableCell style={{paddingLeft:38}}>{tradeSheet.id}</TableCell>
          <TableCell>{formatDate(tradeSheet.date)} - <span style={{color: '#9EA1AF'}}>{formatDateTimeToTime(tradeSheet.date)}</span></TableCell>
          <TableCell>{tradeSheet.created_by.first_name + ' ' + tradeSheet.created_by.last_name}</TableCell>
          <TableCell>{tradeSheet.order_type}</TableCell>
          <TableCell>
            <Grid className={classes.buttonContainer}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                className={classes.actionButton}
                onClick={this.tradeSheetExportClick}
              >Export</Button>
            </Grid>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }
})
