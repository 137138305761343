export default (theme) => ({
  mainContainer: {
    width: '100%',
    maxWidth: 1280,
    padding: '52px 0',
    margin: '0 auto',
    minHeight: 'calc(100vh - 110px)',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: '52px 16px'
    }
  },
  circularProgress: {
    width: '100%',
    height: 'calc(100vh - 220px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});