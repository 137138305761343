import mainLogo from './logo.svg';
import emailSent from './email_sent.svg';
import user from './user.svg';
import move from './move.svg';
import create from './menu_icons/create.svg'
import create_outline from './menu_icons/create_outline.svg'
import export_icon from './menu_icons/export_icon.svg'
import import_icon from './menu_icons/import_icon.svg'
import list from './menu_icons/list.svg'
import decrease_arrow from './decrease_arrow.svg';
import increase_arrow from './increase_arrow.svg';

export default {
    mainLogo,
    icons: {
        emailSent,
        user,
        move,
        increase_arrow,
        decrease_arrow
    },
    menu_icons: {
      create,
      create_outline,
      export_icon,
      import_icon,
      list
    }
};