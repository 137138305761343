export default (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white'
  },
  headerContainer: {
    height: 80,
    maxWidth: 1280,
    width: '100%',
    margin: '0 auto',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 16
    }
  },
  headerLogo: {
    width: 130,
    height: 36,
    objectPosition: 'center',
    objectFit: 'contain',
  },
  navContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '100%'
  },
  navButton: {
    padding: 0,
    paddingBottom: 18,
    marginRight: 34,
    fontSize: 18,
    color: '#504F53',
    boxSizing: 'border-box',
    fontFamily: "'Avenir-Roman'",
    borderRadius: 0,
    '&:first-child': {
      marginLeft: 60
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '&:active': {
      backgroundColor: 'transparent',
    }
  },
  navButtonActive: {
    position: 'relative',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: 5,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 10,
      bottom: 0
    }
  },
  userContainer: {
    float: 'right',
    marginLeft: 'auto',
  },
  userButton: {
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:active': {
      backgroundColor: 'transparent'
    }
  },
  userInfo: {
    maxHeight: '100%',
    display: 'flex',
    marginRight: 10,
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontFamily: "'Avenir-Roman'",
    '& span': {
      fontSize: 12,
      color: "#504F53"
    },
    '& $userName': {
      fontSize: 18
    },
    '& $userHello': {
      position: 'relative',
      top: 6
    }
  },
  userName: {},
  userHello: {},
  menuItemIcon: {
    marginRight: 10
  },
  menuItem: {
    fontFamily: "'Avenir-Roman'",
    fontSize: 14
  }
});