import React from 'react';
import _ from 'lodash';

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import BacktestEditComponent from '../../../components/Backtest/Edit';
import styles from '../styles';
import { BackendReource } from '../../../api/index_v2';
import backtestValidationSchema from '../../../components/Backtest/Form/schema';
import EvergreenWebSocket from '../../../api/websocket';
import { CALCULATION_MESSAGE_TYPE } from '../../BacktestOverview/constants';
import Snackbar from '../../../components/Snackbar';
import { MESSAGES } from './constants';

export default withStyles(styles)(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: '',
      backtest: undefined,
      open: false,
      isEditable: true,
      errors: [],
      popupOpen: false,
      popupVariant: "",
      popupMessage: ""
    }

    this.fetchBacktest = this.fetchBacktest.bind(this);
    this.renderBacktestEditComponent = this.renderBacktestEditComponent.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSaveNew = this.handleSaveNew.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.saveBacktest = this.saveBacktest.bind(this);
    this.toggleEditable = this.toggleEditable.bind(this);
    this.disableEditable = this.disableEditable.bind(this);
    this.allowEditable = this.allowEditable.bind(this);
    this.showPopup = this.showPopup.bind(this);
  }

  componentWillMount() {
    const { computedMatch: { params } } = this.props;

    const id = Number(params.id);

    const socket = new EvergreenWebSocket(`${window.location.host}/socket/`);
    socket.connect();
    socket.onMessage((message) => {
      const data = JSON.parse(message.data);
      switch(data.type) {
        case CALCULATION_MESSAGE_TYPE.START:
          if(parseInt(data.backtest) === parseInt(id)) {
            this.disableEditable();
            this.setState({
              popupVariant: "warning",
              popupMessage: MESSAGES.CALCULATION_START
            });
            this.showPopup();
          }
          break;
        case CALCULATION_MESSAGE_TYPE.PROGRESS:
          if(parseInt(data.backtest) === parseInt(id)) {
            this.disableEditable();
          }
          break;
        case CALCULATION_MESSAGE_TYPE.DONE:
          if(parseInt(JSON.parse(data.backtest)['backtest_id']) === parseInt(id)) {
            this.allowEditable();
            this.setState({
              popupVariant: "success",
              popupMessage: MESSAGES.CALCULATION_FINISHED
            });
            this.showPopup();
          }
          break;
        case CALCULATION_MESSAGE_TYPE.FAIL:
          if(data.backtest === id) {
            this.allowEditable();
            this.setState({
              popupVariant: "success",
              popupMessage: MESSAGES.CALCULATION_FINISHED
            });
            this.showPopup();
          }
          break;
        default: 
          break;
      }
    });

    BackendReource.at(`backtest/${id}/`).get().then(() => {
      this.allowEditable();
    }).catch(() => {
      this.disableEditable();
    });
  }

  componentDidMount() {
    this.fetchBacktest();
  }

  showPopup() {
    this.setState({
      popupOpen: true
    });
    setTimeout(() => {
      this.setState({
        popupOpen: false
      });
    }, 3000);
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  handleInputChange(fieldName) {
    return function(event) {
      let { value } = event.target;

      value = value ? value : null;

      this.setState({
        backtest: {
          ...this.state.backtest,
          [fieldName]: value
        }
      });
    }.bind(this);
  }

  handleSaveNew() {
    const { backtest } = this.state;
    const { computedMatch: { params }, history } = this.props;

    const id = Number(params.id);

    if (_.isInteger(id)) {
      this.toggleEditable();
      backtestValidationSchema.validate(backtest, {abortEarly: false}).then(() => {
        BackendReource.at(`backtests/${id}/`).post({...backtest}).then((response) => {
          history.push(`/backtest-overview`);
          this.toggleEditable();
        }).catch((errors) => {
          this.setState({
            errors,
            popupVariant: "error",
            popupMessage: `${Object.keys(errors).length} error${Object.keys(errors).length > 1 ? 's' : ''} occurred.`
          });
          this.toggleEditable();
          this.showPopup();
        });
      }).catch((err) => {
        const errors = {}
        err.inner.map((error) => {
          errors[error.path] = error.message;
          return null;
        });
        this.setState({
          errors: errors,
          popupVariant: "error",
          popupMessage: `${Object.keys(errors).length} error${Object.keys(errors).length > 1 ? 's' : ''} occurred.`
        });
        this.toggleEditable();
        this.showPopup();
      });
    }
  }

  handleSave() {
    this.setState({
      open: true
    });
  }

  toggleOpen() {
    this.setState({
      open: !this.state.open
    });
  }

  disableEditable() {
    this.setState({
      isEditable: false
    });
  }

  allowEditable() {
    this.setState({
      isEditable: true
    });
  }

  toggleEditable() {
    this.setState({
      isEditable: !this.state.isEditable
    });
  }

  saveBacktest() {
    this.toggleOpen();
    const { backtest } = this.state;
    const { computedMatch: { params }, history } = this.props;

    const id = Number(params.id);

    if (_.isInteger(id)) {
      this.toggleEditable();
      backtestValidationSchema.validate(backtest, {abortEarly: false}).then(() => {
        BackendReource.at(`backtests/${id}/`).put({...backtest}).then((response) => {
          history.push(`/backtest-overview`);
          this.toggleEditable();
        }).catch((errors) => {
          this.setState({
            errors,
            popupVariant: "error",
            popupMessage: `${Object.keys(errors).length} error${Object.keys(errors).length > 1 ? 's' : ''} occurred.`
          });
          this.toggleEditable();
          this.showPopup();
        })
      }).catch((err) => {
        const errors = {}
        err.inner.map((error) => {
          errors[error.path] = error.message;
          return null;
        });
        this.setState({
          errors: errors,
          popupVariant: "error",
          popupMessage: `${Object.keys(errors).length} error${Object.keys(errors).length > 1 ? 's' : ''} occurred.`
        });
        this.toggleEditable();
        this.showPopup();
      });
    }
  }

  fetchBacktest() {
    const { computedMatch: { params }, history } = this.props;

    const id = Number(params.id);
    
    if (_.isInteger(id)) {
      BackendReource.at(`backtests/${id}/`).get().then((response) => {
        this.setState({
          backtest: response.data,
          label: `${response.data.backtest_id} - ${response.data.desc}`
        });
      }).catch(() => {
        history.push('/not-found/');
      });
    }
    else {
      history.push('/not-found/');
    }
  }

  renderBacktestEditComponent() {
    const { classes } = this.props;

    if (this.state.backtest) {
      return <BacktestEditComponent
        label={this.state.label}
        backtest={this.state.backtest} 
        errors={this.state.errors} 
        handleInputChange={this.handleInputChange}
        handleSaveNew={this.handleSaveNew}
        handleSave={this.handleSave}
        editable={this.state.isEditable}/>
    }
    return (
      <Grid className={classes.circularProgress}>
        <CircularProgress size={30} />
      </Grid>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Snackbar 
          variant={this.state.popupVariant}
          message={this.state.popupMessage} 
          snackbarOpen={this.state.popupOpen} 
        />
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Existing data will be deleted.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.saveBacktest} color="primary" variant="contained" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Grid className={classes.mainContainer}>
          {this.renderBacktestEditComponent()}
        </Grid>
      </React.Fragment>
    )
  }
});