import React from 'react';
import _ from 'lodash';

import { withStyles, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';

import EditIcon from'@material-ui/icons/Create';
import AcceptIcon from '@material-ui/icons/CheckCircle';

import styles from '../FundOverview/styles';
import funtTradingStyles from './styles';
import theme from '../../themes/mainTheme';
import Menu from '../../components/FundTrading/Menu';
import FundTradingTable from '../../components/FundTrading/Table';
import FuturesTable from  '../../components/FundTrading/Table/futures';
import Snackbar from '../../components/Snackbar';
import GenerateTradeSheetModal from '../../components/FundTrading/GenerateTradeSheetModal';
import StartNewDayModal from '../../components/FundTrading/StartNewDayModal';

import { BackendReource } from '../../api/index_v2';
import {connect} from "react-redux";


const TabContainer = withStyles({
  ...styles(theme),
  ...funtTradingStyles(theme)
})((props) => {
  const { classes } = props;
  return (
    <Grid className={[classes.tableContainer, classes.tabContainer].join(' ')}>
      { props.children }
    </Grid>
  );
});
class FundTrading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 1,
      fundDataLoading: true,
      funds: undefined,
      fundSelected: undefined,
      nameEditing: false,
      name: "",
      errors: [],
      popupOpen: false,
      popupVariant: "",
      popupMessage: "",
      isImporting: false,
      isGenerating: false,
      startingNewDay: false,
      startNewDayModalOpen: false,
      fundCalculationData: {},
      actionOnTheFund: false,
      generateTradeSheetModalOpen: false,
      orderType: 'close'
    };

    this.handleChangeFundNameClick = this.handleChangeFundNameClick.bind(this);
    this.handleSaveFundNameClick = this.handleSaveFundNameClick.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleNameBlur = this.handleNameBlur.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleExecute = this.handleExecute.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleBasketApprove = this.handleBasketApprove.bind(this);
    this.handleBasketReset = this.handleBasketReset.bind(this);
    this.handleBasketExecute = this.handleBasketExecute.bind(this);
    this.handleInflowOutflowChange = this.handleInflowOutflowChange.bind(this);
    this.handleImportClick = this.handleImportClick.bind(this);
    this.handleImportFieldChange = this.handleImportFieldChange.bind(this);
    this.updateFunds = this.updateFunds.bind(this);
    this.handleModifyContrChange = this.handleModifyContrChange.bind(this);
    this.refreshCalculations = this.refreshCalculations.bind(this);
    this.handleGenerateCLick = this.handleGenerateCLick.bind(this);
    this.renderGenerateTradeSheetModal = this.renderGenerateTradeSheetModal.bind(this);
    this.handleOrderTypeChange = this.handleOrderTypeChange.bind(this);
    this.handleStartNewDayClick = this.handleStartNewDayClick.bind(this);
    this.renderStartNewDayModal = this.renderStartNewDayModal.bind(this);

    this.nameFieldRef = React.createRef();
    this.importFileInput = React.createRef();
  }

  handleChange = (event, value) => {
    if(!this.state.actionOnTheFund){
      const fund = _.find(this.state.funds, (fund) => {
        return +fund.id === +value;
      });

      this.setState({
        index: value,
        fundSelected: fund ? fund : undefined,
        nameEditing: false,
        name: "",
        errors: [],
        fundCalculationData: {}
      });
    }
  };

  handleNameBlur() {
    this.setState({
      nameEditing: false,
      name: ""
    });
  }

  handleChangeFundNameClick() {
    this.setState({
      nameEditing: true,
      name: this.state.fundSelected.name,
      errors: []
    });
    setTimeout(() => {
      this.nameFieldRef.current.focus()
    }, 0);
  }

  handleGenerateCLick(event) {
    event.preventDefault();

    this.setState({
      isGenerating: true
    });
    this.handleGenerateTradeSheetModalClose();

    BackendReource.at(`trade_sheet/generate/`).post({
      date: new Date().toLocaleString('de-DE'),
      order_type: this.state.orderType,
      user_id: this.props.authentication.user.id,
      funds: this.state.funds

    }).then((response) => {
      const funds = _.isString(response.data) ? JSON.parse(response.data.replace(/\bNaN\b/g, "null")) : response.data;
      let newFundSelected = funds[0];
      if (this.state.fundSelected) {
        newFundSelected = _.find(funds, (fund) => {
          return +this.state.fundSelected.id === +fund.id;
        });
      }
      this.setState({
        funds,
        fundSelected: newFundSelected,
        popupVariant: "success",
        popupMessage: 'File to export was generated'
      });
      this.showPopup();
    }).catch(error => {
      this.setState({
        popupVariant: "error",
        popupMessage: 'All funds need to be executed!'
      });
      this.showPopup();
    }).finally(() => {
      this.setState({
        isGenerating: false
      });
    });
  }

  handleSaveFundNameClick() {
    if (this.state.fundSelected) {
      BackendReource.at(`fund/${this.state.fundSelected.id}/`).put({
        "name": this.state.name
      }).then(response => {
        let { funds } = this.state;
        for(let i = 0; i < funds.length; i++) {
          if(funds[i].id === this.state.fundSelected.id) {
            funds[i].name = this.state.name;
          }
        }

        let { fundSelected } = this.state;
        fundSelected.name = this.state.name;

        this.setState({
          nameEditing: false,
          name: "",
          errors: [],
          funds,
          fundSelected,
          popupVariant: "success",
          popupMessage: "Name was saved!"
        });
        this.showPopup();
      }).catch(errors => {
        this.setState({
          errors
        });
      })
    }
  }

  handleNameChange(event) {
    this.setState({
      name: event.target.value
    });
  }

  handleModifyContrChange(basketId, modifyContr) {
    this.setState({
      fundCalculationData: {
        ...this.state.fundCalculationData,
        baskets: {
          ...this.state.fundCalculationData.baskets,
          [basketId]: modifyContr
        }
      }
    });

    clearTimeout(document.timer_id);

    document.timer_id = this.refreshCalculations();
  }

  handleBasketApprove = (basket_id, modify_contr) => {
    // basket_id is a future id
    BackendReource.at(`basket/${basket_id}/approve/`).post({
      fund_id: this.state.fundSelected.id,
      modify_contr: Math.round(modify_contr)
    }).then(response => {
      this.updateFunds(response.data, `Future was approved!`, basket_id )
    })
  };

  handleBasketReset = (basket_id, modify_contr) => {
    // basket_id is a future id
    BackendReource.at(`basket/${basket_id}/reset/`).post({
      fund_id: this.state.fundSelected.id,
      modify_contr: Math.round(modify_contr)
    }).then(response => {
      this.updateFunds(response.data, `Future was reseted!`, basket_id )
    })
  };

  handleBasketExecute = (basket_id) => {
    // basket_id is a future id
    BackendReource.at(`basket/${basket_id}/execute/`).post({
      fund_id: this.state.fundSelected.id
    }).then(response => {
      this.updateFunds(response.data, `Future was executed!`, basket_id)
    })
  };

  handleApprove() {
    if (this.state.fundSelected) {
      this.setState({
        actionOnTheFund: true
      });
      BackendReource.at(`fund/${this.state.fundSelected.id}/approve/`).post(
        {
          "inflow_outflow": this.state.fundSelected.inflow_outflow
        }
      ).then(response => {
        this.updateFunds(response.data, `"${this.state.fundSelected.name}" was approved!`);
        this.setState({
          actionOnTheFund: false
        });
      });
    }
  }

  handleReset() {
    if (this.state.fundSelected) {
      this.setState({
        actionOnTheFund: true
      });
      BackendReource.at(`fund/${this.state.fundSelected.id}/reset/`).post(
        {
          "inflow_outflow": this.state.fundSelected.inflow_outflow
        }
      ).then(response => {
        this.updateFunds(response.data, `"${this.state.fundSelected.name}" was reseted!`);
        this.setState({
          actionOnTheFund: false
        });
      });
    }
  }

  handleExecute() {
    if (this.state.fundSelected) {
      this.setState({
        actionOnTheFund: true
      });
      BackendReource.at(`fund/${this.state.fundSelected.id}/execute/`).post().then(response => {
        this.updateFunds(response.data, `"${this.state.fundSelected.name}" was executed!`);
        this.setState({
          actionOnTheFund: false
        });
      }).catch(error => {
        this.setState({
          popupVariant: "error",
          popupMessage: 'All futures need to be executed!',
          actionOnTheFund: false
        });
        this.showPopup();
      });
    }
  }

  handleImportClick(event) {
    event.preventDefault();

    const { isImporting } = this.state;

    if (!isImporting) {
      this.importFileInput.current.click()
    }
  }

  handleImportFieldChange(event) {

    if (!_.isEmpty(event.target.files)) {
      this.setState({
        isImporting: true
      });
  
      let formData = new FormData();
      formData.append('file', event.target.files[0]);
  
  
      BackendReource
        .at('fund/upload/')
        .post(formData)
        .then(response => {
          this.setState({
            isImporting: false,
            popupVariant: "success",
            popupMessage: "File was successfully  imported!",
          });
          this.showPopup();

          const funds = _.isString(response.data) ? JSON.parse(response.data.replace(/\bNaN\b/g, "null")) : response.data;
          let newFundSelected = funds[0];
          if (this.state.fundSelected) {
            newFundSelected = _.find(funds, (fund) => {
              return +this.state.fundSelected.id === +fund.id;
            });
          }

          this.setState({
            funds,
            fundSelected: newFundSelected
          });
        })
        .catch(errors => {
          this.setState({
            isImporting: false,
            popupVariant: "error",
            popupMessage: "An error has occurred!",
          });
          this.showPopup();
        });
      
      this.importFileInput.current.value = null
    }
  }

  handleInflowOutflowChange(event) {
    this.setState({
      fundSelected: {
        ...this.state.fundSelected,
        inflow_outflow: event.target.value
      }
    });

    clearTimeout(document.timer_id);

    document.timer_id = this.refreshCalculations();
  }

  updateFunds(data, message, future_id, actionWithFuture=true) {
    let fundUpdated = _.isString(data) ? JSON.parse(data.replace(/\bNaN\b/g, "null")) : data;
    let { funds } = this.state;
    let updatedFuture = null;

    for(let i = 0; i < funds.length; i++) {
      if (+funds[i].id === +fundUpdated.id) {
        if(actionWithFuture){
          let stocks_futures = fundUpdated['stocks_futures'];
          let bonds_futures = fundUpdated['bonds_futures'];

          for(let future = 0; future < stocks_futures.length; future++) {
            if(stocks_futures[future].id === future_id) {
              updatedFuture = stocks_futures[future];
              funds[i]['stocks_futures'][future] = updatedFuture;
              fundUpdated = funds[i];
              break;
            }
          }
          for(let future = 0; future < bonds_futures.length; future++) {
            if(bonds_futures[future].id === future_id) {
              updatedFuture = bonds_futures[future];
              funds[i]['bonds_futures'][future] = updatedFuture;
              fundUpdated = funds[i];
              break;
            }
          }
        } else {
          funds[i] = fundUpdated;
          break;
        }
      }
    }

    this.setState({
      funds,
      fundSelected: fundUpdated,
      popupVariant: "success",
      popupMessage: message
    });

    if(!_.isEmpty(message)) {
      this.showPopup();
    }
  }

  componentWillMount() {
    BackendReource.at('fund/').get().then(response => {
      const funds = _.isString(response.data) ? JSON.parse(response.data.replace(/\bNaN\b/g, "null")) : response.data;
      var fundSelected = undefined;

      const { computedMatch: { params }} = this.props;
      if (params.id && _.isNumber(+params.id)) {
        fundSelected = _.find(funds, (fund) => {
          return +params.id === +fund.id;
        });
      }
      else {
        fundSelected = _.isEmpty(funds) ? undefined : funds[0]
      }

      this.setState({
        fundDataLoading: false,
        funds,
        index: fundSelected ? fundSelected.id : undefined,
        fundSelected
      });
    });
  }

  showPopup() {
    this.setState({
      popupOpen: true
    });
    setTimeout(() => {
      this.setState({
        popupOpen: false
      });
    }, 3000);
  }

  refreshCalculations() {
    return setTimeout(() => {
      if (this.state.fundSelected) {
        BackendReource.at(`fund/${this.state.fundSelected.id}/calculate/`).post(
            {
              "inflow_outflow": parseFloat(this.state.fundSelected.inflow_outflow),
              "futures": this.state.fundCalculationData.baskets
            }
        ).then(response => {
          this.updateFunds(response.data, '', '', false);
        });
      }
    }, 1000);
  }

  handleGenerateTradeSheetModalClose = () => {
    this.setState({ generateTradeSheetModalOpen: false });
  };

  handleClickGenerateTradeSheetModalOpen = () => {
    this.setState({ generateTradeSheetModalOpen: true });
  };

  handleOrderTypeChange = event => {
    this.setState({ orderType: event.target.value });
  };

  renderGenerateTradeSheetModal() {
    if(this.state.generateTradeSheetModalOpen) {
      return (
        <GenerateTradeSheetModal
          openModal={this.state.generateTradeSheetModalOpen}
          handleGenerateTradeSheetModalClose={this.handleGenerateTradeSheetModalClose}
          orderType={this.state.orderType}
          handleOrderTypeChange={this.handleOrderTypeChange}
          handleGenerateCLick={this.handleGenerateCLick}
          isGenerating={this.state.isGenerating}
        />
      )
    }
  }

  handleStartNewDayModalOpen = () => {
    this.setState({ startNewDayModalOpen: true });
  };

  handleStartNewDayModalClose = () => {
    this.setState({ startNewDayModalOpen: false });
  };

  renderStartNewDayModal() {
    if(this.state.startNewDayModalOpen) {
      return (
        <StartNewDayModal
          openModal={this.state.startNewDayModalOpen}
          handleStartNewDayClick={this.handleStartNewDayClick}
          handleStartNewDayModalClose={this.handleStartNewDayModalClose}
        />
      )
    }
  }

  handleStartNewDayClick = () => {
    this.setState({
      startingNewDay: true
    });

    this.handleStartNewDayModalClose();

    BackendReource.at(`start_new_day/`).post({

    }).then((response) => {
      const funds = _.isString(response.data) ? JSON.parse(response.data.replace(/\bNaN\b/g, "null")) : response.data;
      let newFundSelected = funds[0];
      if (this.state.fundSelected) {
        newFundSelected = _.find(funds, (fund) => {
          return +this.state.fundSelected.id === +fund.id;
        });
      }
      this.setState({
        funds,
        fundSelected: newFundSelected,
        popupVariant: "success",
        popupMessage: 'New day started'
      });
      this.showPopup();
    }).catch(error => {
      this.setState({
        popupVariant: "error",
        popupMessage: 'New day could not be started!'
      });
      this.showPopup();
    }).finally(() => {
      this.setState({
        startingNewDay: false
      });
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {this.renderGenerateTradeSheetModal()}
        {this.renderStartNewDayModal()}
        <Grid className={classes.mainContainer} style={{ height: !_.isEmpty(this.state.funds) ? 'auto' : 'calc(100vh - 110px)', paddingBottom: 50}}>
          <input
            type="file"
            ref={this.importFileInput}
            style={{display: "none"}}
            onChange={this.handleImportFieldChange}
            accept=".xlsx, .xls"
          />
          <Snackbar
            variant={this.state.popupVariant}
            message={this.state.popupMessage}
            snackbarOpen={this.state.popupOpen}
          />
          <Menu
            handleImportClick={this.handleImportClick}
            isImporting={this.state.isImporting}
            isGenerating={this.state.isGenerating}
            startingNewDay={this.state.startingNewDay}
            handleClickGenerateTradeSheetModalOpen={this.handleClickGenerateTradeSheetModalOpen}
            handleStartNewDayModalOpen={this.handleStartNewDayModalOpen}
          />
          { this.state.fundDataLoading ? (
            <Grid className={classes.circularProgress}>
              <CircularProgress  size={30}/>
            </Grid>
          ) : (
            <React.Fragment>
              {!_.isEmpty(this.state.funds) && (
                <React.Fragment>
                  <AppBar position="static" classes={{
                    root: classes.appBarRoot
                  }}>
                    <Tabs value={this.state.index} onChange={this.handleChange}>
                      {this.state.funds.map((fund, index) => (
                        <Tab disableRipple label={fund.name} key={index} value={fund.id}/>
                      ))}
                    </Tabs>
                  </AppBar>
                  {this.state.fundSelected && (
                    <TabContainer>
                      <div className={classes.fundName}>
                        {this.state.nameEditing ? (
                          <React.Fragment>
                            <div>
                              <TextField
                                value={this.state.name}
                                variant="outlined"
                                className={classes.nameTextField}
                                onChange={this.handleNameChange}
                                error={"name" in this.state.errors}
                                helperText={"name" in this.state.errors ? this.state.errors["name"]: ""}
                                inputProps={{
                                  ref: this.nameFieldRef
                                }}
                              />
                              <AcceptIcon onClick={this.handleSaveFundNameClick}/>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <h1 className={classes.fundHeaderName}>
                              {this.state.fundSelected.name}
                              <EditIcon onClick={this.handleChangeFundNameClick}/>
                            </h1>
                          </React.Fragment>
                        )}
                      </div>
                      <FundTradingTable
                        fund={this.state.fundSelected}
                        handleApprove={this.handleApprove}
                        handleExecute={this.handleExecute}
                        handleReset={this.handleReset}
                        handleInflowOutflowChange={this.handleInflowOutflowChange}
                        actionOnTheFund={this.state.actionOnTheFund}
                      />
                      <FuturesTable
                        fund={this.state.fundSelected}
                        handleBasketApprove={this.handleBasketApprove}
                        handleBasketExecute={this.handleBasketExecute}
                        handleModifyContrChange={this.handleModifyContrChange}
                        handleBasketReset={this.handleBasketReset}
                      />
                    </TabContainer>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Grid>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  authentication: state.get('authentication').toJS(),
});

export default withStyles({
  ...styles(theme),
  ...funtTradingStyles(theme)
})(connect(mapStateToProps)(FundTrading));