import { fromJS } from 'immutable';

import {
  LOGIN_SUCCESSFUL,
  LOGOUT_SUCCESSFUL
} from './constants';

const currentUser = sessionStorage.getItem('user');

const initialState = fromJS({
  user: currentUser ? JSON.parse(currentUser) : null
});

function appReducer(state = initialState, action) {
  switch(action.type) {
    case LOGIN_SUCCESSFUL:
      sessionStorage.setItem('user', JSON.stringify(action.user));
      return state.set('user', fromJS(action.user));
    case LOGOUT_SUCCESSFUL:
      sessionStorage.removeItem('user');
      return state.set('user', null);
    default:
      return state;
  }
}

export default appReducer;