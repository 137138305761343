import React from 'react';
import moment from 'moment';

import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import styles from './styles';
import icons from '../../../images';
import { formatToGerman, formatDate } from '../../../utils/backtestOverviewUtils';
import { BACKTEST_STATUS } from '../../../containers/BacktestOverview/constants';

export default withStyles(styles)((props) => {
  const { classes, nthChild, fund } = props;
  return (
    <React.Fragment>
      <TableRow className={[classes.fundNameHeading, nthChild ? classes.greyBackground : ''].join(' ')}>
        <TableCell colSpan={18} className={classes.fundNameColumn}>{fund.name}</TableCell>
      </TableRow>
      <TableRow className={[classes.topHeading, nthChild ? classes.greyBackground : ''].join(' ')}>
        <TableCell rowSpan={2}>Date</TableCell>
        <TableCell colSpan={4}>Performance</TableCell>
        <TableCell colSpan={3}>Is</TableCell>
        <TableCell colSpan={3}>Should %</TableCell>
        <TableCell rowSpan={2} style={{width: 80}}>Risk Budget %</TableCell>
        <TableCell rowSpan={2}>LPM0</TableCell>
        <TableCell colSpan={4}>Tracking difference</TableCell>
        <TableCell />
      </TableRow>
      <TableRow className={[classes.bottomHeading, nthChild ? classes.greyBackground : ''].join(' ')}>
        <TableCell>1Day</TableCell>
        <TableCell>MTD</TableCell>
        <TableCell>YTD</TableCell>
        <TableCell>P.A.</TableCell>
        <TableCell>Stocks</TableCell>
        <TableCell>Bonds</TableCell>
        <TableCell>Cash</TableCell>
        <TableCell style={{width: 110}}>Stocks</TableCell>
        <TableCell style={{width: 110}}>Bonds</TableCell>
        <TableCell style={{width: 110}}>Cash</TableCell>
        <TableCell>1Day</TableCell>
        <TableCell>MTD</TableCell>
        <TableCell>YTD</TableCell>
        <TableCell>P.A.</TableCell>
        <TableCell rowSpan={2}>
          <Button variant="contained" color="primary" className={classes.fundTradeButton} onClick={props.toTrade(fund.id)}>Trade</Button>
        </TableCell>
      </TableRow>
      <TableRow className={[classes.fundValues, nthChild ? classes.greyBackground : ''].join(' ')}>
        <TableCell>{formatDate(fund.date)}</TableCell>
        <TableCell>{formatToGerman(fund.perf_1d)}</TableCell>
        <TableCell>{formatToGerman(fund.perf_mtd)}</TableCell>
        <TableCell>{formatToGerman(fund.perf_ytd)}</TableCell>
        <TableCell>{formatToGerman(fund.perf_pa)}</TableCell>
        <TableCell>{formatToGerman(fund.is_stocks)}</TableCell>
        <TableCell>{formatToGerman(fund.is_bonds)}</TableCell>
        <TableCell>{formatToGerman(fund.is_cash)}</TableCell>
        {(fund.backtest.bt_id &&fund.backtest.calculation) ? (
          <React.Fragment>
            {fund.backtest.calculation.status === BACKTEST_STATUS.SUCCESS ? (
              <React.Fragment>
                <TableCell className={classes.displayOnHover} style={{maxWidth: 120}}>
                  {formatToGerman(fund.should_stocks)}
                  <span>
                    <React.Fragment>
                      ({fund.should_stocks_diff > 0 ? '+' : ''}
                      {formatToGerman(fund.should_stocks_diff)})
                    </React.Fragment>
                  </span>
                  {fund.should_stocks_diff && fund.should_stocks_diff !== 0 ? (
                    <img src={fund.should_stocks_diff < 0 ? icons.icons.decrease_arrow : icons.icons.increase_arrow} alt="decrease"/>
                    ) : (<span />)}
                </TableCell>
                <TableCell className={classes.displayOnHover} style={{maxWidth: 120}}>
                  {formatToGerman(fund.should_bonds)}
                  <span>
                    <React.Fragment>
                      ({fund.should_bonds_diff > 0 ? '+' : ''}
                      {formatToGerman(fund.should_bonds_diff)})
                    </React.Fragment>
                  </span>
                  {fund.should_bonds_diff && fund.should_bonds_diff !== 0 ? (
                    <img src={fund.should_bonds_diff < 0 ? icons.icons.decrease_arrow : icons.icons.increase_arrow} alt="decrease"/>
                    ) : (<span />)}
                </TableCell>
                <TableCell className={classes.displayOnHover} style={{maxWidth: 120}}>
                  {formatToGerman(fund.should_cash)}
                  <span>
                    <React.Fragment>
                      ({fund.should_cash_diff > 0 ? '+' : ''}
                      {formatToGerman(fund.should_cash_diff)})
                    </React.Fragment>
                  </span>
                  {fund.should_cash_diff && fund.should_cash_diff !== 0 ? (
                    <img src={fund.should_cash_diff < 0 ? icons.icons.decrease_arrow : icons.icons.increase_arrow} alt="decrease"/>
                    ) : (<span />)}
                </TableCell>
                <TableCell>{formatToGerman(fund.risk_budget)}</TableCell>
                <TableCell>{formatToGerman(fund.lpm0)}</TableCell>
                <TableCell>{formatToGerman(fund.tracking_difference_1d)}</TableCell>
                <TableCell>{formatToGerman(fund.tracking_difference_mtd)}</TableCell>
                <TableCell>{formatToGerman(fund.tracking_difference_ytd)}</TableCell>
                <TableCell>{formatToGerman(fund.tracking_difference_pa)}</TableCell>
              </React.Fragment>
            ) : (
              <TableCell colSpan={9} />
            )}
          </React.Fragment>
        ) : (
          <TableCell colSpan={9} />
        )}
      </TableRow>
      <TableRow className={[classes.additionalInfoColumns, nthChild ? classes.greyBackground : ''].join(' ')}>
        <TableCell colSpan={7} />
        <TableCell colSpan={3}>
          <span
            className={
              [
                classes.statusText, 
                (fund.backtest.calculation && fund.backtest.calculation.status === 2) ?  classes.calculationErrorText : ''
              ].join(' ')}
          >
          <b>Calculated:</b>
          <React.Fragment>
            {
              (fund.backtest.bt_id && fund.backtest.calculation) && (
                <React.Fragment>
                  { (fund.backtest.calculation.status !== 3 && fund.backtest.calculation.status !== 4) ? (
                    <React.Fragment>
                      { fund.backtest.calculation.status === 2 ? (
                        <span> Calculation error</span>
                      ) : (
                        <React.Fragment>
                          { moment(fund.backtest.overview.calculated).format('DD.MM.YYYY - HH.mm')}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (<span />)}
                </React.Fragment>
              )
            }
          </React.Fragment>
          </span>
        </TableCell>
        <TableCell colSpan={2} />
        <TableCell colSpan={6}>
          <b>Approved:</b>
          {fund.approved && (` ${fund.approved_by.first_name ? fund.approved_by.first_name[0]: ""}.${fund.approved_by.last_name} / ${moment(fund.approved).format('DD.MM.YYYY - HH.mm')}`)}<br />
          <b>Executed:</b>
          {fund.executed && (` ${fund.executed_by.first_name ? fund.executed_by.first_name[0]: ""}.${fund.executed_by.last_name} / ${moment(fund.executed).format('DD.MM.YYYY - HH.mm')}`)}<br />
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
})