export default (theme) => ({
  appBarRoot: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  tabContainer: {
    minHeight: 600,
    backgroundColor: 'white',
    borderRadius: '0 10px 10px 10px'
  },
  fundHeaderName: {
    fontFamily: 'Arial',
    fontSize: 24,
    color: '#504F53',
    paddingLeft: 38,
    paddingTop: 20,
  },
  circularProgress: {
    width: '100%',
    height: 'calc(100vh - 228px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fundName: {
    display: 'flex',
    
    '& > div': {
      paddingTop: 20,
      paddingLeft: 38,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    '& svg': {
      cursor: 'pointer',
      marginLeft: 20,
      color: '#9F3A7E'
    },

    '& > h1': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    '& > h1 > svg': {
      opacity: 0,
      transition: 'all 0.3s'
    },

    '& > h1:hover > svg': {
      opacity: 1,
    }
  },
  nameTextField: {
    
  }
})