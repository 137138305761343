import { createMuiTheme } from '@material-ui/core/styles';
import { hidden } from 'ansi-colors';

const formaLabelFocused = {
  transform: "translate(5px, -22px) !important",
  fontSize: 14,
  fontFamily: "'Arial'",
  color: "#9EA1AF"
};

export default createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#9F3A7E',
      dark: '#432A4E'
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 50,
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 18,

      },
      containedPrimary: {
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#8A2467'
        },
        '&:active': {
          backgroundColor: '#6F1D53',
          boxShadow: 'none'
        }
      },
      contained: {
        '&$disabled': {
          color: 'white'
        }
      }
    },
    MuiFormLabel: {
      root: {
        transform: "translate(14px, 18px) !important",
        color: '#9EA1AF',
        fontSize: 16,
        fontFamily: "'Arial'",
        '&$focused': {
          ...formaLabelFocused
        },
        '&$filled': {
          ...formaLabelFocused
        }
      },
      filled: {},
      focused: {}
    },
    MuiOutlinedInput: {
      root: {
        boxSizing: 'border-box',
        borderRadius: 4,
        height: 50,
        '& legend': {
          display: 'none'
        },
        '& $notchedOutline': {
          border: "1px solid #EDEDED"
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
         borderColor: '#9396A7'
        },
        '&$focused': {
          '& $notchedOutline': {
            border: "1px solid #9EA1AF"
          }
        },
        '&$disabled': {
          '& $notchedOutline': {
            border: "none"
          },
          '& $input': {
            backgroundColor: '#FAFAFA'
          }
        }
      },
      input: {
        maxHeight: 50,
        borderRadius: 4,
        padding: '17px 20px',
        fontSize: 16,
        fontFamily: "'Arial'",
        color: "#504F53"
      },
      focused: {},
      error: {
        '& $notchedOutline': {
          borderColor: "#FF0000"
        }
      },
      disabled: {}
    },
    MuiPopover: {
      paper: {
        borderRadius: 10
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
        color: '#9F3A7E'
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      },
      root: {
        padding: 0
      }
    },
    MuiFormHelperText: {
      error: {
        fontSize: 14,
        fontWeight: 'bold',
        fontFamily: "'Arial'",
        color: "#9EA1AF",
        marginLeft: '5px !important'
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
        padding: 0
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 16
      }
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 50,
        height: 8,
        backgroundColor: 'transparent !important',
        border: `1px solid #9F3A7E`
      }
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: 'none',
        '&:before': {
          backgroundColor: 'transparent'
        }
      },
      expanded: {
        margin: 0
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        height: '64px !important',
        backgroundColor: '#FAFAFA',
        border: '1px solid #EDEDED',
        fontFamily: "Avenir",
        fontSize: 18,
        color: "#504F53"
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: 0,
        paddingTop: 35,
        paddingBottom: 30,
        display: 'flex',
        flexDirection: 'column'
      }
    },
    MuiDialogActions: {
      root: {
        padding: 20,
        margin: 0,
        borderTop: '2px solid #EDEDED'
      }
    },
    MuiDialogTitle: {
      root: {
        textAlign: 'center'
      }
    },
    MuiCheckbox: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiTabs: {
      root: {
        paddingTop: 50,
        marginTop: -70,
        overflow: "visible"
      },
      scroller: {
        overflow: "visible"
      },
      fixed: {
        overflow: "visible",
        overflowX: "visible"
      },
      indicator: {
        display: 'none'
      }
    },
    MuiTab: {
      root: {
        width: 200,
        height: 40,
        backgroundColor: 'white',
        border: '2px solid #9F3A7E',
        borderRadius: '10px 10px 0 0',
        marginRight: 5,
        boxSizing: 'border-box'
      },
      label: {
        fontFamily: 'Avenir',
        fontSize: 14,
        color: '#9F3A7E',
        textTransform: "none",
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        textOverflow: "ellipsis"
      },
      labelContainer: {
        whiteSpace: 'nowrap',
        overflow: hidden,
      },
      selected: {
        boxShadow: '-1px -20px 53px -20px rgba(0,0,0,0.18)',
        height: 53,
        border: 'none',
        '& $labelContainer': {
          marginBottom: 5
        },
        '& $label': {
          color: '#A2A5B2',
        }
      },
      textColorInherit: {
        opacity: 1
      }
    }
  }
});
