import {scrollStyle} from '../../../BacktestOverview/styles';

export default () => ({
  cancelButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  backtestModal: {
    '& > div:nth-child(2)': {
      borderRadius: 10
    }
  },
  modalContent: {
    ...scrollStyle,
    marginRight: 10,
    marginBottom: 10
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  backtestsContainer: {
    height: 400,
    display: 'flex',
    ...scrollStyle
  },
  backtestListText: {
    paddingLeft: 0,
    maxWidth: 230,
  },
  backtestListTextPrimary: {
    fontFamily: 'Avenir',
    fontSize: 18,
    color: '#504F53',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  backtestsListItem: {
    padding: 0,
    height: 30,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  modalTitle: {
    '& > h6': {
    fontFamily: 'Avenir-Roman',
    fontSize: 24,
    }
  }
})