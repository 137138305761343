import _ from 'lodash';
import {germanFormat} from './formater'
import moment from "moment";

export const saveBacktestItemOrdering = (items) => {
    let ordering = items.map((item) => {
        return item.bt_id;
    });

    localStorage.setItem('backtestOrdering', JSON.stringify(ordering));
};

export const getBacktestItemOrdering = () => JSON.parse(localStorage.getItem('backtestOrdering'));

export const sortBacktestItems = (items) => {
    const ordering = getBacktestItemOrdering();

    const orderedItems = [...ordering.map((index) => {
        const i = _.findIndex(items, (item) => {
            return item.bt_id === index;
        });
        if (i !== -1) {
            return items[i];
        }
        return undefined;
    }), ...items.map((item) => {
        const i = _.findIndex(ordering, (id) => {
            return id === item.bt_id;
        });
        if (i === -1) {
            return item;
        }
        return undefined;
    })];

    return _.remove(orderedItems, (value) => {
        return value !== undefined;
    })
};

export const valueOrPlaceholder = (value, placeholder, fixed=undefined) => {
    if (value === 0) {
        return value.toFixed(2);
    }
    return value ? (fixed ? value.toFixed(fixed) : value) : placeholder;
};

export const formatToGerman = (value, placeholder='-', fixed=undefined) => {
    if (value || value === 0) {
        return germanFormat(fixed ? value.toFixed(fixed) : (value === 0 || value % 1 === 0 ? value.toFixed(2) : value), {
            'decimal': ',',
            'grouping': '.',
            'prefix': '',
            'suffix': ''
        });
    }
    return placeholder;
};

export const formatDate = (date, placeholder='-') => {
    if (date) {
        return moment(date).format('DD.MM.YYYY');
    }
    return placeholder;
};

export const formatDateTimeToTime = (date, placeholder='-') => {
    if (date) {
        return moment.utc(date).format('HH:mm');
    }
    return placeholder;
};