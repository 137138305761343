export default (theme) => ({
  topHeading: {
    '& > th': {
      fontFamily: 'Avenir',
      fontSize: 13,
      color: '#9EA1AF',
      padding: '0 5px',
      whiteSpace: 'nowrap'
    },
    '& > th:first-child': {
      paddingLeft: 0
    },
    height: 10,
    verticalAlign: 'bottom'
  },
  bottomHeading: {
    '& > th': {
      fontFamily: 'Avenir-Roman',
      fontSize: 13,
      color: '#9EA1AF',
      padding: '0 5px'
    },
    '& > th:first-child': {
      paddingLeft: 0
    },
    height: 10
  },
  fundTable: {
    marginTop: 15,
    borderBottom: '2px solid #EDEDED'
  },
  tableContainer: {
    padding: '0 38px'
  },
  futuresTable: {
    marginBottom: 20,
    '& thead tr': {
      height: 30,
      verticalAlign: 'top'
    },
    '& tbody tr:nth-child(2n-1)': {
      backgroundColor: '#F8F8F8'
    },
    '& tr td:first-child': {
      paddingLeft: 38
    },
    '& tr th:first-child': {
      paddingLeft: 38
    }
  },
  futuresHeading: {
    height: 48,
    '& > th': {
    }
  }
})