import React from 'react';
import _ from 'lodash';

import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from "@material-ui/core/FormControl/FormControl";
import TextField from "@material-ui/core/TextField/TextField";
import Grid from "@material-ui/core/Grid/Grid";

export default class BacktestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: ['advanced', 'normal', 'optional'],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleExpansionChange = this.handleExpansionChange.bind(this);
  }

  handleExpansionChange = panel => event => {
    const { expanded } = this.state;

    if (expanded.includes(panel)) {
      this.setState({
        expanded: _.filter(expanded, (value) => {return value !== panel})
      });
    }
    else {
      this.setState({
        expanded: [...this.state.expanded, panel]
      });
    }
  };
  
  handleSubmit(event) {
    event.preventDefault();
  }
  
  render() {
    const { classes, backtest, errors} = this.props;
    return(
      <form
        autoComplete="off"
        onSubmit={this.handleSubmit}
      >
        <ExpansionPanel square expanded={this.state.expanded.includes('normal')} onChange={this.handleExpansionChange('normal')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <span className={classes.expansionLabel}>Normal Parameters</span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'backtest_id' in errors}
                label={'Bt id'}
                variant="outlined"
                type="number"
                value={backtest.backtest_id}
                onChange={this.props.handleInputChange('backtest_id')}
                helperText={errors['backtest_id'] ? errors['backtest_id'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                error={'desc' in errors}
                label={'Bt name'}
                variant="outlined"
                value={backtest.desc}
                onChange={this.props.handleInputChange('desc')}
                helperText={errors['desc'] ? errors['desc'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <Grid className={classes.fieldHolder}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'basket_eq' in errors}
                  label={'Basket eq'}
                  variant="outlined"
                  type="number"
                  value={backtest.basket_eq}
                  onChange={this.props.handleInputChange('basket_eq')}
                  helperText={errors['basket_eq'] ? errors['basket_eq'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'basket_fi' in errors}
                  label={'Basket fi'}
                  variant="outlined"
                  type="number"
                  value={backtest.basket_fi}
                  onChange={this.props.handleInputChange('basket_fi')}
                  helperText={errors['basket_fi'] ? errors['basket_fi'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
            </Grid>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'basket_cash' in errors}
                label={'Cash index'}
                variant="outlined"
                type="number"
                value={backtest.basket_cash}
                onChange={this.props.handleInputChange('basket_cash')}
                helperText={errors['basket_cash'] ? errors['basket_cash'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <Grid className={classes.fieldHolder}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'floor_bt' in errors}
                  label={'Floor bt'}
                  variant="outlined"
                  type="number"
                  value={backtest.floor_bt}
                  onChange={this.props.handleInputChange('floor_bt')}
                  helperText={errors['floor_bt'] ? errors['floor_bt'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'floor_subs' in errors}
                  label={'Floor subs'}
                  variant="outlined"
                  type="number"
                  value={backtest.floor_subs}
                  onChange={this.props.handleInputChange('floor_subs')}
                  helperText={errors['floor_subs'] ? errors['floor_subs'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
            </Grid>
            <Grid className={classes.fieldHolder}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'min_eq' in errors}
                  label={'Min eq'}
                  variant="outlined"
                  type="number"
                  value={backtest.min_eq}
                  onChange={this.props.handleInputChange('min_eq')}
                  helperText={errors['min_eq'] ? errors['min_eq'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'min_fi' in errors}
                  label={'Min fi'}
                  variant="outlined"
                  type="number"
                  value={backtest.min_fi}
                  onChange={this.props.handleInputChange('min_fi')}
                  helperText={errors['min_fi'] ? errors['min_fi'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
            </Grid>
            <Grid className={classes.fieldHolder}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'max_eq' in errors}
                  label={'Max eq'}
                  variant="outlined"
                  type="number"
                  value={backtest.max_eq}
                  onChange={this.props.handleInputChange('max_eq')}
                  helperText={errors['max_eq'] ? errors['max_eq'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'max_fi' in errors}
                  label={'Max fi'}
                  variant="outlined"
                  type="number"
                  value={backtest.max_fi}
                  onChange={this.props.handleInputChange('max_fi')}
                  helperText={errors['max_fi'] ? errors['max_fi'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
            </Grid>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'leverage' in errors}
                label={'Leverage'}
                variant="outlined"
                type="number"
                value={backtest.leverage}
                onChange={this.props.handleInputChange('leverage')}
                helperText={errors['leverage'] ? errors['leverage'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel square expanded={this.state.expanded.includes('advanced')} onChange={this.handleExpansionChange('advanced')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <span className={classes.expansionLabel}>Advanced Parameters</span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'no_no_days_in_period' in errors}
                label={'No no days in period'}
                variant="outlined"
                type="number"
                value={backtest.no_no_days_in_period}
                onChange={this.props.handleInputChange('no_no_days_in_period')}
                helperText={errors['no_no_days_in_period'] ? errors['no_no_days_in_period'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <Grid className={[classes.fieldHolder, classes.threePerColumn].join(' ')}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'cov_method' in errors}
                  label={'Cov method'}
                  variant="outlined"
                  type="number"
                  value={backtest.cov_method}
                  onChange={this.props.handleInputChange('cov_method')}
                  helperText={errors['cov_method'] ? errors['cov_method'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'cov_kappa' in errors}
                  label={'Cov kappa'}
                  variant="outlined"
                  type="number"
                  value={backtest.cov_kappa}
                  onChange={this.props.handleInputChange('cov_kappa')}
                  helperText={errors['cov_kappa'] ? errors['cov_kappa'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'cov_days_back' in errors}
                  label={'Cov days back'}
                  variant="outlined"
                  type="number"
                  value={backtest.cov_days_back}
                  onChange={this.props.handleInputChange('cov_days_back')}
                  helperText={errors['cov_days_back'] ? errors['cov_days_back'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
            </Grid>
            <Grid className={classes.fieldHolder}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'trade_filter_eq' in errors}
                  label={'Trade filter eq'}
                  variant="outlined"
                  type="number"
                  value={backtest.trade_filter_eq}
                  onChange={this.props.handleInputChange('trade_filter_eq')}
                  helperText={errors['trade_filter_eq'] ? errors['trade_filter_eq'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'trade_filter_fi' in errors}
                  label={'Trade filter fi'}
                  variant="outlined"
                  type="number"
                  value={backtest.trade_filter_fi}
                  onChange={this.props.handleInputChange('trade_filter_fi')}
                  helperText={errors['trade_filter_fi'] ? errors['trade_filter_fi'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
            </Grid>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'sets_mcsim' in errors}
                label={'Sets mcsim'}
                variant="outlined"
                type="number"
                value={backtest.sets_mcsim}
                onChange={this.props.handleInputChange('sets_mcsim')}
                helperText={errors['sets_mcsim'] ? errors['sets_mcsim'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'paths_mcsim' in errors}
                label={'Paths mcsim'}
                variant="outlined"
                type="number"
                value={backtest.paths_mcsim}
                onChange={this.props.handleInputChange('paths_mcsim')}
                helperText={errors['paths_mcsim'] ? errors['paths_mcsim'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'no_subs' in errors}
                label={'No subs'}
                variant="outlined"
                type="number"
                value={backtest.no_subs}
                onChange={this.props.handleInputChange('no_subs')}
                helperText={errors['no_subs'] ? errors['no_subs'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'opt_profile' in errors}
                label={'Opt profile'}
                variant="outlined"
                type="number"
                value={backtest.opt_profile}
                onChange={this.props.handleInputChange('opt_profile')}
                helperText={errors['opt_profile'] ? errors['opt_profile'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <Grid className={classes.fieldHolder}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'floor_cap_factor' in errors}
                  label={'Floor cap factor'}
                  variant="outlined"
                  type="number"
                  value={backtest.floor_cap_factor}
                  onChange={this.props.handleInputChange('floor_cap_factor')}
                  helperText={errors['floor_cap_factor'] ? errors['floor_cap_factor'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'moving_cap_factor' in errors}
                  label={'Moving cap factor'}
                  variant="outlined"
                  type="number"
                  value={backtest.moving_cap_factor}
                  onChange={this.props.handleInputChange('moving_cap_factor')}
                  helperText={errors['moving_cap_factor'] ? errors['moving_cap_factor'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
            </Grid>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'hold_cap' in errors}
                label={'Hold cap'}
                variant="outlined"
                type="number"
                value={backtest.hold_cap}
                onChange={this.props.handleInputChange('hold_cap')}
                helperText={errors['hold_cap'] ? errors['hold_cap'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'moving_floor_factor' in errors}
                label={'Moving floor factor'}
                variant="outlined"
                type="number"
                value={backtest.moving_floor_factor}
                onChange={this.props.handleInputChange('moving_floor_factor')}
                helperText={errors['moving_floor_factor'] ? errors['moving_floor_factor'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'hold_floor' in errors}
                label={'Hold floor'}
                variant="outlined"
                type="number"
                value={backtest.hold_floor}
                onChange={this.props.handleInputChange('hold_floor')}
                helperText={errors['hold_floor'] ? errors['hold_floor'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <Grid className={[classes.fieldHolder, classes.threePerColumn].join(' ')}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'ovnt_risk_eq' in errors}
                  label={'Ovnt risk eq'}
                  variant="outlined"
                  type="number"
                  value={backtest.ovnt_risk_eq}
                  onChange={this.props.handleInputChange('ovnt_risk_eq')}
                  helperText={errors['ovnt_risk_eq'] ? errors['ovnt_risk_eq'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'ovnt_risk_fi' in errors}
                  label={'Ovnt risk fi'}
                  variant="outlined"
                  type="number"
                  value={backtest.ovnt_risk_fi}
                  onChange={this.props.handleInputChange('ovnt_risk_fi')}
                  helperText={errors['ovnt_risk_fi'] ? errors['ovnt_risk_fi'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'ovnt_risk_csh' in errors}
                  label={'Ovnt risk csh'}
                  variant="outlined"
                  type="number"
                  value={backtest.ovnt_risk_csh}
                  onChange={this.props.handleInputChange('ovnt_risk_csh')}
                  helperText={errors['ovnt_risk_csh'] ? errors['ovnt_risk_csh'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
            </Grid>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'crash_corr' in errors}
                label={'Crash corr'}
                variant="outlined"
                type="number"
                value={backtest.crash_corr}
                onChange={this.props.handleInputChange('crash_corr')}
                helperText={errors['crash_corr'] ? errors['crash_corr'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'lpm_risk' in errors}
                label={'Lpm risk'}
                variant="outlined"
                type="number"
                value={backtest.lpm_risk}
                onChange={this.props.handleInputChange('lpm_risk')}
                helperText={errors['lpm_risk'] ? errors['lpm_risk'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel square expanded={this.state.expanded.includes('optional')} onChange={this.handleExpansionChange('optional')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <span className={classes.expansionLabel}>Optional Parameters</span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'dividends_op' in errors}
                label={'Dividends op'}
                variant="outlined"
                type="number"
                value={backtest.dividends_op}
                onChange={this.props.handleInputChange('dividends_op')}
                helperText={errors['dividends_op'] ? errors['dividends_op'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'model_op' in errors}
                label={'Model op'}
                variant="outlined"
                type="number"
                value={backtest.model_op}
                onChange={this.props.handleInputChange('model_op')}
                helperText={errors['model_op'] ? errors['model_op'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'randomization_op' in errors}
                label={'Randomization op'}
                variant="outlined"
                type="number"
                value={backtest.randomization_op}
                onChange={this.props.handleInputChange('randomization_op')}
                helperText={errors['randomization_op'] ? errors['randomization_op'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'mngmt_fee' in errors}
                label={'Mngmt fee'}
                variant="outlined"
                type="number"
                value={backtest.mngmt_fee}
                onChange={this.props.handleInputChange('mngmt_fee')}
                helperText={errors['mngmt_fee'] ? errors['mngmt_fee'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'perf_fee' in errors}
                label={'Perf fee'}
                variant="outlined"
                type="number"
                value={backtest.perf_fee}
                onChange={this.props.handleInputChange('perf_fee')}
                helperText={errors['perf_fee'] ? errors['perf_fee'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
            <Grid className={classes.fieldHolder}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'export_to_file' in errors}
                  label={'Export to file'}
                  variant="outlined"
                  type="number"
                  value={backtest.export_to_file}
                  onChange={this.props.handleInputChange('export_to_file')}
                  helperText={errors['export_to_file'] ? errors['export_to_file'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'export_path' in errors}
                  label={'Export path'}
                  variant="outlined"
                  value={backtest.export_path}
                  onChange={this.props.handleInputChange('export_path')}
                  helperText={errors['export_path'] ? errors['export_path'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
            </Grid>
            <Grid className={classes.fieldHolder}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'trading_fee_eq' in errors}
                  label={'Trading fee eq'}
                  variant="outlined"
                  type="number"
                  value={backtest.trading_fee_eq}
                  onChange={this.props.handleInputChange('trading_fee_eq')}
                  helperText={errors['trading_fee_eq'] ? errors['trading_fee_eq'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'trading_fee_fi' in errors}
                  label={'Trading fee fi'}
                  variant="outlined"
                  type="number"
                  value={backtest.trading_fee_fi}
                  onChange={this.props.handleInputChange('trading_fee_fi')}
                  helperText={errors['trading_fee_fi'] ? errors['trading_fee_fi'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
            </Grid>
            <Grid className={classes.fieldHolder}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'bal_1_eq' in errors}
                  label={'Bal 1 eq'}
                  variant="outlined"
                  type="number"
                  value={backtest.bal_1_eq}
                  onChange={this.props.handleInputChange('bal_1_eq')}
                  helperText={errors['bal_1_eq'] ? errors['bal_1_eq'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'bal_1_fi' in errors}
                  label={'Bal 1 fi'}
                  variant="outlined"
                  type="number"
                  value={backtest.bal_1_fi}
                  onChange={this.props.handleInputChange('bal_1_fi')}
                  helperText={errors['bal_1_fi'] ? errors['bal_1_fi'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
            </Grid>
            <Grid className={classes.fieldHolder}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'bal_2_eq' in errors}
                  label={'Bal 2 eq'}
                  variant="outlined"
                  type="number"
                  value={backtest.bal_2_eq}
                  onChange={this.props.handleInputChange('bal_2_eq')}
                  helperText={errors['bal_2_eq'] ? errors['bal_2_eq'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'bal_2_fi' in errors}
                  label={'Bal 2 fi'}
                  variant="outlined"
                  type="number"
                  value={backtest.bal_2_fi}
                  onChange={this.props.handleInputChange('bal_2_fi')}
                  helperText={errors['bal_2_fi'] ? errors['bal_2_fi'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
            </Grid>
            <Grid className={classes.fieldHolder}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'bal_3_eq' in errors}
                  label={'Bal 3 eq'}
                  variant="outlined"
                  type="number"
                  value={backtest.bal_3_eq}
                  onChange={this.props.handleInputChange('bal_3_eq')}
                  helperText={errors['bal_3_eq'] ? errors['bal_3_eq'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  error={'bal_3_fi' in errors}
                  label={'Bal 3 fi'}
                  variant="outlined"
                  type="number"
                  value={backtest.bal_3_fi}
                  onChange={this.props.handleInputChange('bal_3_fi')}
                  helperText={errors['bal_3_fi'] ? errors['bal_3_fi'] : ''}
                  disabled={!this.props.editable}
                  InputLabelProps={{
                    filled: true
                  }}
                />
              </FormControl>
            </Grid>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={'row_def_id' in errors}
                label={'Row def id'}
                variant="outlined"
                type="number"
                value={backtest.row_def_id}
                onChange={this.props.handleInputChange('row_def_id')}
                helperText={errors['row_def_id'] ? errors['row_def_id'] : ''}
                disabled={!this.props.editable}
                InputLabelProps={{
                  filled: true
                }}
              />
            </FormControl>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </form>
    )
  }
}