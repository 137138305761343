export default (theme) => ({
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 30,
    background: theme.palette.primary.dark,
    [theme.breakpoints.down('md')]: {
      padding: '0 16px'
    }
  },
  container: {
    maxWidth: 1280,
    height: '100%',
    margin: '0 auto'
  },
  footerText: {
    color: "#9EA1AF",
    fontSize: 12,
    fontFamily: 'Arial'
  },
  footerItem: {
    display: 'flex',
    alignItems: 'center'
  }
});