import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash'

import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import styles from './styles';
import fundOverviewStyles from '../../FundOverview/Item/styles';
import { formatToGerman } from '../../../utils/backtestOverviewUtils';
import CircularProgress from "@material-ui/core/CircularProgress";

const mapStateToProps = (state) => ({
  authentication: state.get('authentication').toJS(),
});

export default connect(mapStateToProps)(withStyles({...styles(), ...fundOverviewStyles()})(class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canReset: (props.fund.approved && !props.fund.reseted) || moment(props.fund.reseted) < moment(props.fund.approved),
      actionOnTheFund: props.actionOnTheFund,
      error: false
    };

    this.handleApproveCLick = this.handleApproveCLick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.handleExecuteClick = this.handleExecuteClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.fund.id !== this.props.fund.id) {
      this.setState({
        canApprove: undefined,
      })
    }
    if (prevProps !== this.props) {
      this.setState({
        actionOnTheFund: this.props.actionOnTheFund,
        canReset: (this.props.fund.approved && !this.props.fund.reseted) || moment(this.props.fund.reseted) < moment(this.props.fund.approved),
      })
    }
  }

  handleApproveCLick() {
    const { fund } = this.props;
    if ( _.isNull(fund.inflow_outflow) || _.isEmpty(fund.inflow_outflow.toString()) || +fund.inflow_outflow + +fund.volume < 0) {
      this.setState({
        error: true
      });
      return;
    }
    this.setState({
      error: false
    });
    this.props.handleApprove();
  }

  handleResetClick() {
    const { fund } = this.props;
    this.props.handleReset(fund.id, this.state.modifyContr);
  }

  handleExecuteClick() {
    const { fund } = this.props;
    this.props.handleExecute(fund.id);
  }

  render() {
  const { classes, fund , authentication, handleInflowOutflowChange} = this.props;

  return (
    <React.Fragment>
      <TableRow className={classes.fundValues}>
        <TableCell style={{paddingLeft: 0}}><span style={{whiteSpace: 'nowrap'}}>{formatToGerman(fund.volume)} <span>&#8364;</span></span></TableCell>
        <TableCell style={{width: 300}}>
          <div className={classes.fundInflowCOntainer}>
            <TextField
              disabled={this.state.canReset}
              type="number"
              variant="outlined"
              error={this.state.error}
              classes={{
                root: classes.fundInflowInput,
              }}
              value={(!_.isNull(fund.inflow_outflow) &&
                      (!_.isString(fund.inflow_outflow) ||
                       (_.isString(fund.inflow_outflow) &&
                       !_.isEmpty(fund.inflow_outflow))) &&
                      _.isNumber(+fund.inflow_outflow)) ?  +fund.inflow_outflow : ""}
              onChange={handleInflowOutflowChange}
            /> 
            <span style={{marginLeft: 5}}>&#8364;</span>
          </div>
        </TableCell>
        <TableCell style={{width: 210}}><b style={{whiteSpace: "nowrap"}}>{ fund.new_volume ? (
        <React.Fragment>
          {formatToGerman(fund.new_volume)}
          <span>&#8364;</span>
        </React.Fragment>) : "-"}</b></TableCell>
        <TableCell>
          <div>
            {this.state.canReset ?
              <Button
                className={classes.resetButton}
                variant="outlined"
                color="primary"
                disabled={this.state.actionOnTheFund}
                onClick={this.handleResetClick}
              >{this.state.actionOnTheFund ? <CircularProgress size={20} /> : 'Reset'}</Button>
            :
              <Button 
                className={classes.approveButton}
                variant="contained"
                color="primary"
                onClick={this.handleApproveCLick}
                disabled={this.state.actionOnTheFund}
              >{this.state.actionOnTheFund ? <CircularProgress size={20} color={"inherit"} /> : 'Approve'}</Button>
            }
            {
              fund.approved &&
              +fund.approved_by.id !== +authentication.user.id &&
              !fund.executed &&
              !this.state.actionOnTheFund && (
                <Button
                  className={classes.executeButton}
                  variant="contained"
                  color="primary"
                  onClick={this.handleExecuteClick}
                >Execute</Button>
              )
            }
          </div>
        </TableCell>
        <TableCell>
          <span className={classes.ownerInfo}> 
          {
            fund.approved && (
              <React.Fragment>
                {`${fund.approved_by.first_name ? fund.approved_by.first_name[0]: ""}.${fund.approved_by.last_name}`}<br />
                {moment(fund.approved).format('DD.MM.YYYY - HH.mm')}
              </React.Fragment>
            )
          }
          </span>
        </TableCell>
        <TableCell>
          <span className={classes.ownerInfo}>
          {
            fund.executed && (
              <React.Fragment>
                {`${fund.executed_by.first_name ? fund.executed_by.first_name[0]: ""}.${fund.executed_by.last_name}`}<br />
                {moment(fund.executed).format('DD.MM.YYYY - HH.mm')}
              </React.Fragment>
            )
          }
          </span>
        </TableCell>
      </TableRow>
      <TableRow className={classes.fundValues} style={{height: 20}}>
        <TableCell style={{paddingLeft: 0}} className={classes.cashHead}>Cash</TableCell>
        <TableCell className={classes.cashHead}>Cash Index</TableCell>
        <TableCell colSpan={4}></TableCell>
      </TableRow>
      <TableRow className={classes.fundValues}>
        <TableCell style={{paddingLeft: 0}}>{fund.cash ? (
          <React.Fragment>
            {
              formatToGerman(fund.cash)
            }
          </React.Fragment>
        ) : "-"}</TableCell>
        <TableCell>{!_.isEmpty(fund.cash_futures) ? (fund.cash_futures[0].ric) : ("-")}</TableCell>
        <TableCell colSpan={4}></TableCell>
      </TableRow>
    </React.Fragment>
  )
        }
}));