import * as yup from 'yup';

const VALID_NUMBER_MESSAGE = '${label} should be a valid number.';

const getNumberValidation = (label) => yup.number().typeError(VALID_NUMBER_MESSAGE).required().label(label);
const transformNumberValidation = (label) => yup.string()
  .typeError(VALID_NUMBER_MESSAGE)
  .transform((cv, ov) => {return ov === '' ? '' : cv;})
  .nullable();

export default yup.object().shape({
  backtest_id: getNumberValidation('Bt id').integer(),
  desc: yup
    .string()
    .label('Bt name')
    .max(255)
    .required(),
  basket_eq: getNumberValidation('Basket eq').integer(),
  basket_fi: getNumberValidation('Basket fi').integer(),
  basket_cash: getNumberValidation('Cash index').integer(),
  floor_bt: getNumberValidation('Floor bt')
    .min(0)
    .max(1),
  floor_subs: getNumberValidation('Floor subs'),
  no_no_days_in_period: getNumberValidation('No no days in period').integer(),
  cov_method: getNumberValidation('Cov method').oneOf([1, 2]),
  cov_kappa: getNumberValidation('Cov kappa')
    .min(0)
    .max(1),
  cov_days_back: getNumberValidation('Cov days back').integer(),
  min_eq: getNumberValidation('Min eq'),
  min_fi: getNumberValidation('Min fi'),
  max_eq: getNumberValidation('Max eq'),
  max_fi: getNumberValidation('Max fi'),
  leverage: getNumberValidation('Leverage'),
  trade_filter_eq: getNumberValidation('Trade filter eq'),
  trade_filter_fi: getNumberValidation('Trade filter fi'),
  sets_mcsim: getNumberValidation('Sets mcsim').integer(),
  paths_mcsim: getNumberValidation('Paths mcsim').integer(),
  no_subs: getNumberValidation('No subs').integer(),
  opt_profile: getNumberValidation('Opt profile')
    .min(0)
    .max(3),
  floor_cap_factor: getNumberValidation('Floor cap factor')
    .default(() => { return 1;}),
  moving_cap_factor: getNumberValidation('Moving cap factor')
    .default(() => { return 0.75;}),
  hold_cap: getNumberValidation('Hold cap')
    .default(() => { return 0;}),
  moving_floor_factor: getNumberValidation('Moving floor factor').default(() => { return 0.5;}),
  hold_floor: getNumberValidation('Hold floor')
    .min(0)
    .max(1)
    .default(() => { return 1;}),
  ovnt_risk_eq: getNumberValidation('Ovnt risk eq').default(() => { return -0.13;}),
  ovnt_risk_fi: getNumberValidation('Ovnt risk fi').default(() => { return -0.025;}),
  ovnt_risk_csh: getNumberValidation('Ovnt risk csh').default(() => { return 0;}),
  crash_corr: getNumberValidation('Crash corr').default(() => { return 1;}),
  lpm_risk: getNumberValidation('Lpm risk').default(() => { return 1.64;}),

  dividends_op: transformNumberValidation('Dividends op'),
  model_op: transformNumberValidation('Model op')
    .default(() => { return 1; }),
  randomization_op: transformNumberValidation('Randomization op'),
  export_to_file: transformNumberValidation('Export to file'),
  export_path: yup
    .string()
    .max(255)
    .label('Export path').nullable(),
  mngmt_fee: transformNumberValidation('Mngmt fee'),
  perf_fee: transformNumberValidation('Perf fee'),
  trading_fee_eq: transformNumberValidation('Trading fee eq'),
  trading_fee_fi: transformNumberValidation('Trading fee fi'),
  bal_1_eq: transformNumberValidation('Bal 1 eq'),
  bal_1_fi: transformNumberValidation('Bal 1 fi'),
  bal_2_eq: transformNumberValidation('Bal 2 eq'),
  bal_2_fi: transformNumberValidation('Bal 2 fi'),
  bal_3_eq: transformNumberValidation('Bal 3 eq'),
  bal_3_fi: transformNumberValidation('Bal 3 fi'),
  row_def_id: transformNumberValidation('Row def id')
})