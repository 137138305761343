import React from 'react';
import {
  Redirect,
  Route,
  withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { PERMISSION_TYPE } from './constants';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: props.location.pathname + props.location.search
    };
  }

  renderComponent = () => {
    const {
      component: DefaultComponent,
      authentication,
      authenticationType,
    } = this.props;

    let renderComponent = <DefaultComponent {...this.props} />

    switch(authenticationType) {
      case PERMISSION_TYPE.ANONYMOUS_ONLY:
        if (authentication.user) {
          renderComponent = <Redirect to="not-fount" />
        }
        break;
      case PERMISSION_TYPE.AUTHORIZED_ONLY:
        if (!authentication.user) {
          const pathName = this.state.currentPath === '/' ? '/login/' : `/login/?next=${this.state.currentPath}`;
          renderComponent = <Redirect to={pathName} />
        }
        break;
      default:
        break;
    }

    return renderComponent;
  }

  render () {
    const { component, authentication, ...others } = this.props;
    return (
      <Route {...others} render={this.renderComponent} />
    )
  }
}

const mapStateToProps = (state) => ({
  authentication: state.get('authentication').toJS(),
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));