const buttonStyles = {
  minHeight: 30,
  width: 100,
  padding: 0,
  fontFamily: 'Avenir',
  fontSize: 14,
}

export default () => ({
  fundInflowCOntainer: {
    display: 'flex',
    alignItems: 'center'
  },
  fundInflowInput: {
    height: 30,
    
    '& input': {
      padding: '0 20px',

      '&:disabled'  : {
        '&:hover': {
          cursor: 'not-allowed'
        }
      }
    },

    '& fieldset': {
     borderRadius: 0,
     borderColor: '#C6C8D1'
    },

  },
  modifyContrField: {
    height: 30,
    width: 60,
    fontFamily: 'Avenir-Roman',
    fontSize: 16,
    padding: '6px 4px',
    color: '#504F53',

    '&:disabled'  : {
      backgroundColor: '#FAFAFA',
      '&:hover': {
        backgroundColor: '#FAFAFA',
        cursor: 'not-allowed'
      }
    }
  },
  modifyContrFieldError: {
    border: '1px solid red',
  },
  futuresValue: {
    verticalAlign: "top",
    '& > td': {
      paddingTop: '15px !important'
    }
  },
  approveButton: {
    ...buttonStyles,
    marginRight: 10
  },
  disabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
     '&:hover': {
       backgroundColor: 'rgba(0, 0, 0, 0.12)',
     }
  },
  resetButton: {
    ...buttonStyles,
    marginRight: 10
  },
  executeButton: {
    ...buttonStyles,
    border: '2px solid #9F3A7E',
    '&:hover': {
      border: '2px solid #9F3A7E'
    }
  },
  ownerInfo: {
    display: 'block',
    padding: '10px 0',
    fontFamily: 'Avenir-Roman',
    fontSize: 13,
    color: '#9EA1AF',
    whiteSpace: 'nowrap'
  },
  cashHead: {
    fontFamily: "'Avenir' !important",
    fontSize: '13px !important',
    color: '#9EA1AF !important',
  }
})