import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import styles from "../../BacktestOverview/Menu/styles";

function StartNewDayModal(props) {
  const {
    classes,
    openModal,
    handleStartNewDayModalClose,
    handleStartNewDayClick
  } = props;

  return (
    <Dialog
      open={openModal}
      onClose={handleStartNewDayModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>Confirm the start of a new day</DialogTitle>
      <DialogContent>
        <DialogContentText component="div" id="alert-dialog-description">
          <Grid className={classes.errorDetailsContainer} style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <FormControl component="fieldset" className={classes.formControl}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleStartNewDayModalClose}
              >Cancel</Button>
            </FormControl>
            <FormControl component="fieldset" className={classes.formControl}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleStartNewDayClick}
              >Confirm</Button>
            </FormControl>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(StartNewDayModal);
