export default (theme) => ({
  formContainer: {
    height: '100%',
    width: '100%',
    boxShadow: '0 0px 80px -40px rgba(0,0,0,0.25)',
    borderRadius: 10,
    padding: 20
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
      '& > h2': {
        margin: 0,
        fontFamily: "'Avenir-Roman'",
        color: "#504F53"
     }
  },
  form: {
    '& > form': {
      marginTop: 45,
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'column',
    }
  },
  formControl: {
    marginBottom: 35,
  },
  formControlContainer: {
    display: 'flex'
  },
  submitButton: {
    marginLeft: 10,
    height: 50,
    fontFamily: "Avenir",
    fontSize: 18
  },
  buttonContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  fieldHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    '& $formControl': {
      width: '49%'
    }
  },
  threePerColumn: {
    '& $formControl': {
      width: '32%'
    }
  },
  expansionLabel: {
    color: theme.palette.primary.main
  },
  ExpansionPanelDetails: {
    '& $formControl:last-child': {
      marginBottom: 0
    }
  }
});