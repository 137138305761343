import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";

import styles from "../../BacktestOverview/Menu/styles";
import images from "../../../images";


function SimpleAppBar(props) {
  const { classes } = props;

  return (
    <AppBar position="static" className={classes.menu}>
      <Toolbar className={classes.menuToolbar}>
        <Typography className={classes.menuTypography}>
          <Link to="" className={classes.menuLink}>
            <img src={images.menu_icons.export_icon} alt="export" className={classes.menuLinkIcon}/>
            Export
          </Link>
        </Typography>
        {/*
        <Typography className={classes.menuTypography}>
          <Link to="" className={classes.menuLink}>
            <img src={images.menu_icons.list} alt="create" className={classes.menuLinkIcon}/>
            Get Log Files
          </Link>
        </Typography>
        */}
      </Toolbar>
    </AppBar>
  );
}

SimpleAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleAppBar);
