import * as yup from 'yup';

const INVALID_DATE_TYPE = '${label} should be a valid date.'

export const longTermCalculationScheme = yup.object().shape({
  startDate: yup
    .date()
    .required()
    .typeError(INVALID_DATE_TYPE)
    .label('From'),
  endDate: yup
    .date()
    .required()
    .typeError(INVALID_DATE_TYPE)
    .label('Till'),
});

export const dailyCalculationScheme = yup.object().shape({
  startDate: yup
    .date()
    .required()
    .typeError(INVALID_DATE_TYPE)
    .label('From'),
});