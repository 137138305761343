export const BACKTEST_STATUS = {
    SUCCESS: 1,
    FAILURE: 2,
    IN_PROGRESS: 3,
    IN_QUEUE: 4,
}

export const CALCULATION_MESSAGE_TYPE = {
    PROGRESS: 'backtest.progress',
    START: 'backtest.start',
    DONE: 'backtest.done',
    FAIL: 'backtest.fail',
    NEW: 'backtest.new'
}