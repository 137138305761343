import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import { SortableElement, SortableHandle } from 'react-sortable-hoc';

import images from '../../../images';
import { valueOrPlaceholder, formatToGerman, formatDate } from '../../../utils/backtestOverviewUtils';


const DragHandle = SortableHandle(() => (
  <img src={images.icons.move} alt="Move"/>
));

export default SortableElement(({backtestOverview, classes, history, handleModalOpen, openErrorModal, handleStopCalculationClick}) => {
  const handleEditClick = (id) => event => {
    history.push(`/backtest/edit/${id}`);
  }

  return (
  <TableRow className={classes.tableBodyRow}>
    <TableCell className={classes.tableBodyHeadCell}>
      <DragHandle />
      <Tooltip title={`${backtestOverview.bt_id}-${backtestOverview.bt_name}`} placement="top">
        <span>{backtestOverview.bt_id}-{backtestOverview.bt_name}</span>
      </Tooltip>
    </TableCell>
    { backtestOverview.calculation ? (
      <React.Fragment>
        { (backtestOverview.calculation.status === 1 || !backtestOverview.calculation) ? (
          <React.Fragment>
            { _.isEmpty(backtestOverview.overview) ? (
              <TableCell colSpan={11}></TableCell>
            ) : (
              <React.Fragment>
                <TableCell style={{width: 125}}>{`${formatDate(backtestOverview.overview.start_date)} - ${formatDate(backtestOverview.overview.end_date)}`}</TableCell>
                <TableCell>{formatToGerman(backtestOverview.overview.daily_bt_perf)}</TableCell>
                <TableCell>{formatToGerman(backtestOverview.overview.ytd)}</TableCell>
                <TableCell>{formatToGerman(backtestOverview.overview.pa)}</TableCell>
                <TableCell>{formatToGerman(backtestOverview.overview.weight_eq)}</TableCell>
                <TableCell>{formatToGerman(backtestOverview.overview.weight_fi)}</TableCell>
                <TableCell>{formatToGerman(backtestOverview.overview.weight_cash)}</TableCell>
                <TableCell>{formatToGerman(backtestOverview.overview.risk_budget)}</TableCell>
                <TableCell>{formatToGerman(backtestOverview.overview.lpm)}</TableCell>
                <TableCell>{formatToGerman(backtestOverview.overview.vol_ex_ante)}</TableCell>
                <TableCell>{formatToGerman(backtestOverview.overview.vol_ex_post)}</TableCell>
                <TableCell>{formatToGerman(backtestOverview.overview.var)}</TableCell>
              </React.Fragment>
            ) }
          </React.Fragment>
        ) : (
          <React.Fragment>
            {backtestOverview.calculation.status === 2 ? (
              <TableCell colSpan={11}></TableCell>
            ) : (
              <React.Fragment>
                {backtestOverview.calculation.status === 3 ? (
                  <React.Fragment>
                    <TableCell colSpan={3}><span className={classes.calculatingStatusText}>{backtestOverview.calculation.message ? (<React.Fragment>{backtestOverview.calculation.message}</React.Fragment>) : ('Calculating Backtest...')}</span></TableCell>
                    <TableCell colSpan={8} className={classes.calculationStatusRow}>
                      <LinearProgress color="primary" variant="determinate" value={backtestOverview.calculation.progress ? backtestOverview.calculation.progress : 0} className={classes.calculationStatusProgress}/>
                    </TableCell>
                  </React.Fragment>
                ) : (
                  <TableCell colSpan={11}>
                    <span className={classes.calculatingStatusText}>In Queue</span>
                  </TableCell>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    ) : (<TableCell colSpan={11}></TableCell>)}
    <TableCell>
      <Grid className={classes.buttonContainer}>
      { (!backtestOverview.calculation || (backtestOverview.calculation && backtestOverview.calculation.status !== 3 && backtestOverview.calculation.status !== 4)) ? (
          <React.Fragment>
            <Button variant="outlined" size="small" color="primary" className={classes.actionButton} onClick={handleEditClick(backtestOverview.bt_id)}>Edit</Button>
            <Button variant="contained" size="small" color="primary" className={classes.actionButton} onClick={handleModalOpen(backtestOverview.bt_id)}>Run</Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              style={{width: 160}}
              className={classes.actionButton}
              onClick={handleStopCalculationClick(backtestOverview.bt_id)}
            >
              {backtestOverview.calculation.status === 3 ? "Stop calculation" : "Remove from Queue" }
            </Button>
          </React.Fragment>
        )
      }
      </Grid>
      <Grid className={classes.buttonContainer}>
        <span 
          className={
            [
              classes.statusText, 
              (backtestOverview.calculation && backtestOverview.calculation.status === 2) ?  classes.calculationErrorText : ''
            ].join(' ')}
        >
          <b 
            onClick={(backtestOverview.calculation && backtestOverview.calculation.status === 2) ? openErrorModal(backtestOverview.bt_id) : () => {}}
          >
            Calculated: 
          </b>
          <React.Fragment>
            {
              backtestOverview.calculation && (
                <React.Fragment>
                  { (backtestOverview.calculation.status !== 3 && backtestOverview.calculation.status !== 4) ? (
                    <React.Fragment>
                      { backtestOverview.calculation.status === 2 ? (
                        <span onClick={openErrorModal(backtestOverview.bt_id)}> Calculation error</span>
                      ) : (
                        <React.Fragment>
                          { moment(backtestOverview.overview.calculated).format('DD.MM.YYYY - HH.mm')}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (<span></span>)}
                </React.Fragment>
              )
            }
          </React.Fragment>
        </span>
      </Grid>
    </TableCell>
  </TableRow>    
)});
