import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core";
import styles from "../../BacktestOverview/Menu/styles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

function GenerateTradeSheetModal(props) {
  const {
    classes,
    openModal,
    handleGenerateTradeSheetModalClose,
    orderType,
    handleOrderTypeChange,
    handleGenerateCLick
  } = props;

  return (
    <Dialog
      open={openModal}
      onClose={handleGenerateTradeSheetModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>Select the order type</DialogTitle>
      <DialogContent>
        <DialogContentText component="div" id="alert-dialog-description">
          <Grid className={classes.errorDetailsContainer}>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                aria-label="Gender"
                name="gender1"
                className={classes.group}
                value={orderType}
                onChange={handleOrderTypeChange}
              >
                <FormControlLabel value="close" control={<Radio color="primary" />} label="Close" />
                <FormControlLabel value="market" control={<Radio color="primary" />} label="Market" />
              </RadioGroup>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateCLick}
              >Generate Trade Sheet</Button>
            </FormControl>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGenerateTradeSheetModalClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(GenerateTradeSheetModal);
