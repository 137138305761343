import React from 'react';
import _ from 'lodash';

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import styles from './styles';

export default withStyles(styles)((props) => {
  const { 
    classes,
    open,
    closeModal,
    backtests,
    dailyBacktests,
    fetchDaiyBacktests
  } = props;

  let renderBacktests = (param) => {
    return backtests.map((backtest, index) => {
      if ( index % 2 === param ) {
        return ( 
          <ListItem
            button
            key={backtest.id}
            disableRipple
            classes={{root: classes.backtestsListItem}}
            onClick={handleCheckboxClick(backtest)}
          >
            <Checkbox
              color="primary"
              checked={dailyBacktests.includes(backtest.id)}
              disableRipple
              disabled={dailyBacktests.length >= 3 && !dailyBacktests.includes(backtest.id)}
            ></Checkbox>
            <ListItemText classes={{
              primary: classes.backtestListTextPrimary,
              root: classes.backtestListText}} primary={`${backtest.id}-${backtest.name}`}/>
          </ListItem>
        );
      }
    });
  }

  let handleCheckboxClick = (backtest) => () => {

    if ( dailyBacktests.length < 3 || (dailyBacktests.length === 3 && dailyBacktests.includes(backtest.id))) {
      let selectedBacktests = dailyBacktests;

      if (selectedBacktests.includes(backtest.id)) {
        selectedBacktests = _.filter(selectedBacktests, (value) => {
          return value !== backtest.id;
        })
      }
      else {
        selectedBacktests = [...selectedBacktests, backtest.id]
      }

      props.handleDailyBacktestsChanged(selectedBacktests);
    } 
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      className={classes.backtestModal}
    >
      <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>Show Daily Backtest</DialogTitle>
      <DialogContent className={classes.modalContent}>
          <Grid className={classes.backtestsContainer}>
            <React.Fragment>
              { backtests ? (
                <React.Fragment>
                  <List>
                    {renderBacktests(0)}
                  </List>
                  <List>
                    {renderBacktests(1)}
                  </List>
                </React.Fragment>
              ) : (
                <div className={classes.loaderContainer}>
                  <CircularProgress size={25}/>
                </div>
              )}
            </React.Fragment>
          </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} disableRipple className={classes.cancelButton}>
          Cancel
        </Button>
        <Button
          onClick={fetchDaiyBacktests}
          variant="contained"
          color="primary"
        >
          Add selected backtests
        </Button>
      </DialogActions>
    </Dialog>
  )
});
