import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash'

import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import styles from './styles';
import fundOverviewStyles from '../../FundOverview/Item/styles';
import { formatToGerman } from '../../../utils/backtestOverviewUtils';
import { germanFormat } from '../../../utils/formater';
import CircularProgress from "@material-ui/core/CircularProgress";

const mapStateToProps = (state) => ({
  authentication: state.get('authentication').toJS(),
});

export default connect(mapStateToProps)(withStyles({...styles(), ...fundOverviewStyles()})(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canReset: (props.future.trading.approved && !props.future.trading.reseted) || moment(props.future.trading.reseted) < moment(props.future.trading.approved),
      actionOnTheFuture: false,
      error: false,
      modifyContr: (
        props.future.trading.modify_contr ||
        props.future.trading.modify_contr === 0 &&
        (props.future.trading.approved &&
          (!props.future.trading.executed ||
            (props.future.trading.executed &&
              moment(props.future.trading.executed) < moment(props.future.trading.approved))))
          ? props.future.trading.modify_contr
          : '')
    };

    this.handleApproveClick = this.handleApproveClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.handleExecuteClick = this.handleExecuteClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps.future) !== JSON.stringify(this.props.future)){
      this.setState({
        actionOnTheFuture: false,
        canReset: (this.props.future.trading.approved && !this.props.future.trading.reseted) || moment(this.props.future.trading.reseted) < moment(this.props.future.trading.approved),
        modifyContr: (
          this.props.future.trading.modify_contr ||
          this.props.future.trading.modify_contr === 0 &&
          (this.props.future.trading.approved &&
            (!this.props.future.trading.executed ||
              (this.props.future.trading.executed &&
                moment(this.props.future.trading.executed) < moment(this.props.future.trading.approved))))
            ? this.props.future.trading.modify_contr
            : ''),
        error: false
      });
    }
  }

  handleApproveClick() {
    const { future } = this.props;

    if (_.isNull(this.state.modifyContr) || _.isEmpty(this.state.modifyContr.toString()) || (future.amount + future.trading.pending_contract + future.trading.pending_contract_prev_day < -this.state.modifyContr)) {
      this.setState({
        error: true
      });
      return;
    }
    this.setState({
      error: false,
      actionOnTheFuture: true
    });
    this.props.handleBasketApprove(future.id, this.state.modifyContr);
  }

  handleResetClick() {
    const { future } = this.props;
    this.setState({
      actionOnTheFuture: true
    });
    this.props.handleBasketReset(future.id, this.state.modifyContr);
  }

  handleExecuteClick() {
    const { future } = this.props;
    this.setState({
      actionOnTheFuture: true
    });
    this.props.handleBasketExecute(future.id);
  }

  handleInputChange(event) {
    const { handleModifyContrChange } = this.props;

    this.setState({
      modifyContr: event.target.value
    });

    handleModifyContrChange(this.props.future.id, event.target.value);
  }

  render() {
  const { classes, future, authentication} = this.props;

  const integerFormatSettings = {
      'decimal': ',',
      'grouping': '.',
      'prefix': '',
      'suffix': '',
      'fraction': 0
  };

  const integerFormatSettingsWithOneDecimal = {
    'decimal': ',',
    'grouping': '.',
    'prefix': '',
    'suffix': '',
    'fraction': 1
  };

  return (
    <React.Fragment>
      <TableRow className={[classes.fundValues].join(' ')} style={{verticalAlign: 'top'}}>
        <TableCell style={{paddingTop: 17, height: 68}}><b>{future.futr_id}</b></TableCell>
        <TableCell style={{paddingTop: 17}}>{future.contract}</TableCell>
        <TableCell style={{paddingTop: 17}}>{formatToGerman(future.price)}</TableCell>
        <TableCell style={{paddingTop: 17}}>{formatToGerman(future.excahnge_rate_to_eur)}</TableCell>
        <TableCell style={{paddingTop: 17}}>
          {future.amount || future.amount === 0 ? germanFormat(future.amount, integerFormatSettings) : '-'}/{formatToGerman(future.future_weight_in_perc)}%
        </TableCell>
        <TableCell style={{paddingTop: 17}}>
          <React.Fragment>
            {future.trading.pending_contract_prev_day ? germanFormat(future.trading.pending_contract_prev_day, integerFormatSettings) : '-'}
            /
            {future.trading.pending_contract ? germanFormat(future.trading.pending_contract, integerFormatSettings) : '-'}
          </React.Fragment>
        </TableCell>
        <TableCell className={classes.displayOnHover} style={{maxWidth: 120, paddingTop: 17}}>
          {formatToGerman(future.new_future_weight)}
          <span>
            <React.Fragment>
              ({future.recommended_changes_in_weight > 0 ? '+' : ''}
              {formatToGerman(future.recommended_changes_in_weight)})
            </React.Fragment>
          </span>
        </TableCell>
        <TableCell style={{paddingTop: 17}}>{future.recommended_num_of_contracts || future.recommended_num_of_contracts === 0 ? germanFormat(future.recommended_num_of_contracts, integerFormatSettingsWithOneDecimal) : '-'}</TableCell>
        <TableCell style={{paddingTop: 17}}>{formatToGerman(future.trade_result)}%</TableCell>
        <TableCell style={{paddingTop: 10}}>
          <div className={classes.fundInflowCOntainer}>
            <input
              disabled={this.state.canReset}
              type="number"
              className={[classes.modifyContrField, this.state.error ? classes.modifyContrFieldError : ''].join(' ')}
              value={this.state.modifyContr}
              onChange={this.handleInputChange}
            /> 
          </div>
        </TableCell>
        <TableCell style={{paddingTop: 10}}>
          <div style={{whiteSpace: 'nowrap'}}>
            {this.state.canReset ?
              <Button
                className={classes.resetButton}
                variant="outlined"
                color="primary"
                disabled={this.state.actionOnTheFuture}
                onClick={this.handleResetClick}
              >{this.state.actionOnTheFuture ? <CircularProgress size={20} /> : 'Reset'}</Button>
            :
              <Button
                className={classes.approveButton}
                variant="contained"
                color="primary"
                disabled={this.state.actionOnTheFuture}
                onClick={this.handleApproveClick}
              >{this.state.actionOnTheFuture ? <CircularProgress size={20} color={"inherit"} /> : 'Approve'}</Button>
            }
            {
              future.trading.approved &&
              +future.trading.approved_by.id !== +authentication.user.id &&
              !future.trading.executed &&
              !this.state.actionOnTheFuture && (
                <Button
                  className={classes.executeButton}
                  variant="contained"
                  color="primary"
                  onClick={this.handleExecuteClick}
                >Execute</Button>
              )
            }
          </div>
        </TableCell>
        <TableCell>
          <span className={classes.ownerInfo}>
            <b>Approved:</b><br />
            {
              future.trading.approved && (
                <React.Fragment>
                  {`${future.trading.approved_by.first_name ? future.trading.approved_by.first_name[0]: ""}.${future.trading.approved_by.last_name}`}<br />
                  {moment(future.trading.approved).format('DD.MM.YYYY - HH.mm')}
                </React.Fragment>
              )
            }
          </span>
        </TableCell>
        <TableCell>
          <span className={classes.ownerInfo}>
            <b>Executed:</b><br />
            {
              future.trading.executed && (
                <React.Fragment>
                  {`${future.trading.executed_by.first_name ? future.trading.executed_by.first_name[0]: ""}.${future.trading.executed_by.last_name}`}<br />
                  {moment(future.trading.executed).format('DD.MM.YYYY - HH.mm')}
                </React.Fragment>
              )
            }
          </span>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}}))