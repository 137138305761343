export const scrollStyle = {
  '&::-webkit-scrollbar': {
    width: 4,
    height: 4
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 5px grey',
    borderRadius: 3
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#9EA1AF',
    borderRadius: 3
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#9EA1AF',
  }
};

export default (theme) => ({
  tableContainer: {
    height: '100%',
    width: '100%',
    boxShadow: '0 0px 80px -40px rgba(0,0,0,0.25)',
    borderRadius: 10,
    overflow: 'auto',
  },
  table: {
    display: 'block',
    height: '100%',
    width: '100%',
    maxWidth: '100%',
    minWidth: 1270,
  },
  tableHead: {
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
    borderBottom: '2px solid #EDEDED',
    '& tr th': {
      paddingLeft: 3,
      paddingRight: 3,
      paddingBottom: 16,
      fontSize: 13,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      color: '#9EA1AF',
      height: '31px !important',
      textAlign: 'left',
    },
    '& tr:first-child th': {
      paddingBottom: 0
    },
    '& tr': {
      height: '31px !important',
    },

    '& tr th:last-child': {
      width: 200
    },
    '& tr th:first-child': {
      width: 250
    },
  },
  tableCellPadding: {
    paddingBottom: '16px !important'
  },
  alignBottom: {
    '& th': {
      verticalAlign: 'bottom'
    }
  },
  alignTop: {
    '& th': {
      verticalAlign: 'top',
      whiteSpace: 'nowrap'
    }
  },
  tableHeadCellHelp: {
    '& th': {
      fontWeight: 'normal !important'
    }
  },
  tableBody: {
    '& tr:nth-child(2n -1)': {
      backgroundColor: '#F8F8F8'
    },
    display: 'block',
    maxHeight: 'calc(100% - 65px)',
    overflowY: 'auto',
    ...scrollStyle
  },
  tableBodyRow: {
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
    '& td': {
      padding: '15px 3px',
      fontSize: 16,
      fontFamily: 'Arial',
      color: '#504F53',
      verticalAlign: 'top'
    },
    '& td:last-child': {
      padding: '8px 3px',
      minWidth: 200,
      width: 200
    },
    '& td:first-child': {
      width: 250,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      paddingRight: 5
    },
    '& $tableBodyHeadCell': {
      paddingLeft: 12,
      fontWeight: 'bold',
      
      '& img': {
        marginRight: 11
      },
      '& img:hover': {
        cursor: 'pointer'
      },
      '& img:active': {
        cursor: 'move'
      }
    }
  },
  tableBodyHeadCell: {},
  buttonContainer: {
    '& $actionButton': {
      border: `2px solid ${theme.palette.primary.main}`,
      fontSize: 14,
      fontFamily: 'Avenir',
      fontWeight: 'bold',
      minHeight: '30px !important',
      width:75,
      padding: 0
    },
    '& $actionButton:first-child': {
      marginRight: 10
    }
  },
  actionButton: {},
  statusText: {
    fontSize: 13,
    fontFamily: 'Arial',
    color: '#9EA1AF'
  },
  calculatingStatusText: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: 'Avenir',
    fontWeight: 'bold',
  },
  calculationStatusProgress: {
    marginTop: 6
  },
  calculationStatusRow: {
    paddingRight: '20px !important'
  },
  calculationErrorText: {
    color: 'red',
    '&:hover': {
      cursor: 'pointer'
    }
  }
})