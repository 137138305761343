import React from 'react';
import moment from 'moment';

import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

import styles from './styles';
import icons from '../../../../images';
import { formatToGerman } from '../../../../utils/backtestOverviewUtils';

export default withStyles(styles)((props) => {
  const { classes } = props;

  return (
    <Table style={{marginTop: 35, minWidth: 1250}}>
      <TableHead className={classes.tableHeader}>
        <TableRow className={classes.topHead}>
          <TableCell>Daily Backtest</TableCell>
          <TableCell colSpan={3}>Performance</TableCell>
          <TableCell colSpan={3}>Should</TableCell>
          <TableCell rowSpan={2} className={classes.topSectionHeading}>Risk Budget</TableCell>
          <TableCell rowSpan={2} className={classes.topSectionHeading}>LPM0</TableCell>
          <TableCell rowSpan={2} className={classes.topSectionHeading}>
            <Button
              variant="contained"
              color="primary"
              className={classes.chooseBacktestButton}
              onClick={props.openBacktestsModal}
            >Choose backtests</Button>
          </TableCell>
        </TableRow>
        <TableRow className={classes.bottomHead}>
          <TableCell></TableCell>
          <TableCell className={classes.topSectionHeading}>1Day</TableCell>
          <TableCell className={classes.topSectionHeading}>MTD</TableCell>
          <TableCell className={classes.topSectionHeading}>P.A.</TableCell>
          <TableCell className={classes.topSectionHeading} style={{width: 110}}>Stocks</TableCell>
          <TableCell className={classes.topSectionHeading} style={{width: 110}}>Bonds</TableCell>
          <TableCell className={classes.topSectionHeading} style={{width: 110}}>Cash</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableBody}>
      {props.loading ? (
        <TableRow className={classes.loaderIndicatorRow}>
          <TableCell colSpan={10}>
            <div className={classes.circularContainer}>
              <CircularProgress size={25}/>
            </div>
          </TableCell>
        </TableRow>
      ) : (
        <React.Fragment>
          {props.backtests && (
            props.backtests.map((backtest) => {
              return (
                <TableRow className={classes.backtestDetailRow}>
                  <TableCell><span className={classes.backtestHeading}>{`${backtest.bt_id}-${backtest.bt_name}`}</span></TableCell>
                  { backtest.calculation ? (
                    <React.Fragment>
                      { (backtest.calculation.status === 1 || !backtest.calculation) ? (
                            <React.Fragment>
                              <TableCell>{formatToGerman(backtest.overview.daily_bt_perf)}</TableCell>
                              <TableCell>{formatToGerman(backtest.overview.mtd)}</TableCell>
                              <TableCell>{formatToGerman(backtest.overview.pa)}</TableCell>
                              <TableCell className={classes.displayOnHover} style={{maxWidth: 120}}>
                                {formatToGerman(backtest.overview.weight_eq)}
                                <span>
                                <React.Fragment>
                                    ({backtest.overview.should_stocks_diff > 0 ? '+' : ''}
                                    {formatToGerman(backtest.overview.should_stocks_diff)})
                                  </React.Fragment>
                                </span>
                                {backtest.overview.should_stocks_diff && backtest.overview.should_stocks_diff !== 0 ? (
                                  <img src={backtest.overview.should_stocks_diff < 0 ? icons.icons.decrease_arrow : icons.icons.increase_arrow} alt="decrease"/>
                                  ) : (<span></span>)}
                              </TableCell>
                              <TableCell className={classes.displayOnHover} style={{maxWidth: 120}}>
                                {formatToGerman(backtest.overview.weight_fi)}
                                <span>
                                <React.Fragment>
                                    ({backtest.overview.should_bonds_diff > 0 ? '+' : ''}
                                    {formatToGerman(backtest.overview.should_bonds_diff)})
                                  </React.Fragment>
                                </span>
                                {backtest.overview.should_bonds_diff && backtest.overview.should_bonds_diff !== 0 ? (
                                  <img src={backtest.overview.should_bonds_diff < 0 ? icons.icons.decrease_arrow : icons.icons.increase_arrow} alt="decrease"/>
                                  ) : (<span></span>)}
                              </TableCell>
                              <TableCell className={classes.displayOnHover} style={{maxWidth: 120}}>
                                {formatToGerman(backtest.overview.weight_cash)}
                                <span>
                                  <React.Fragment>
                                    ({backtest.overview.should_cash_diff > 0 ? '+' : ''}
                                    {formatToGerman(backtest.overview.should_cash_diff)})
                                  </React.Fragment>
                                </span>
                                {backtest.overview.should_cash_diff && backtest.overview.should_cash_diff !== 0 ? (
                                  <img src={backtest.overview.should_cash_diff < 0 ? icons.icons.decrease_arrow : icons.icons.increase_arrow} alt="decrease"/>
                                  ) : (<span></span>)}
                              </TableCell>
                              <TableCell>{formatToGerman(backtest.overview.risk_budget)}</TableCell>
                              <TableCell>{formatToGerman(backtest.overview.lpm)}</TableCell>
                            </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {backtest.calculation.status === 2 ? (
                            <TableCell colSpan={8}></TableCell>
                          ) : (
                            <React.Fragment>
                              {backtest.calculation.status === 3 ? (
                                <React.Fragment>
                                  <TableCell colSpan={3}><span className={classes.calculatingStatusText}>{backtest.calculation.message ? (<React.Fragment>{backtest.calculation.message}</React.Fragment>) : ('Calculating Backtest...')}</span></TableCell>
                                  <TableCell colSpan={5} className={classes.calculationStatusRow}>
                                    <LinearProgress color="primary" variant="determinate" value={backtest.calculation.progress ? backtest.calculation.progress : 0} className={classes.calculationStatusProgress}/>
                                  </TableCell>
                                </React.Fragment>
                              ) : (
                                <TableCell colSpan={8}>
                                  <span className={classes.calculatingStatusText}>In Queue</span>
                                </TableCell>
                              )}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (<TableCell colSpan={8}></TableCell>)}
                  <TableCell className={classes.calculatedDate}>
                    <span
                      className={
                        [
                          classes.statusText, 
                          (backtest.calculation && backtest.calculation.status === 2) ?  classes.calculationErrorText : ''
                        ].join(' ')}
                    ><b>Calculated: </b>
                    <React.Fragment>
                      {
                        backtest.calculation && (
                          <React.Fragment>
                            { (backtest.calculation.status !== 3 && backtest.calculation.status !== 4) ? (
                              <React.Fragment>
                                { backtest.calculation.status === 2 ? (
                                  <span>Calculation error</span>
                                ) : (
                                  <React.Fragment>
                                    { moment(backtest.overview.calculated).format('DD.MM.YYYY - HH.mm')}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            ) : (<span></span>)}
                          </React.Fragment>
                        )
                      }
                    </React.Fragment>
                  </span></TableCell>
                </TableRow>
              )
            })
          )}
        </React.Fragment>
      )}
      </TableBody>
    </Table>
  )
});