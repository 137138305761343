import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";

import CircularProgress from '@material-ui/core/CircularProgress';

import styles from "../../BacktestOverview/Menu/styles";
import images from "../../../images";
import Button from "@material-ui/core/Button";


function SimpleAppBar(props) {
  const { 
    classes,
    isImporting,
    isGenerating,
    startingNewDay,
    handleImportClick,
    handleClickGenerateTradeSheetModalOpen,
    handleStartNewDayModalOpen
  } = props;

  return (
    <AppBar position="static" className={classes.menu}>
      <Toolbar className={classes.menuToolbar}>
        <Typography className={classes.menuTypography}>
          <Link to="" className={classes.menuLink} onClick={handleImportClick}>
            { isImporting ? (
              <CircularProgress size={20} style={{color: '#504F53', marginRight: 10}}/>
            ) : (
              <img src={images.menu_icons.import_icon} alt="omport" className={classes.menuLinkIcon}/>
            )}
            Import
          </Link>
        </Typography>
        <Button
          className={classes.generateButton}
          variant="contained"
          color="primary"
          disabled={startingNewDay}
          onClick={handleStartNewDayModalOpen}
        >{startingNewDay ?
          (<span>
            <CircularProgress size={20} color={"inherit"} />
            <span className={classes.btnText}>Start New Day</span>
          </span>) :
          'Start New Day'
          }
        </Button>
        <Button
          className={classes.generateButton}
          variant="contained"
          color="primary"
          disabled={isGenerating}
          onClick={handleClickGenerateTradeSheetModalOpen}
        >{isGenerating ?
          (<span>
            <CircularProgress size={20} color={"inherit"} />
            <span className={classes.btnText}>Generate Trade Sheet</span>
          </span>) :
          'Generate Trade Sheet'}
        </Button>
        {/*
        <Typography className={classes.menuTypography}>
          <Link to="" className={classes.menuLink}>
            <img src={images.menu_icons.list} alt="create" className={classes.menuLinkIcon}/>
            Get Log Files
          </Link>
        </Typography>
        */}
      </Toolbar>
    </AppBar>
  );
}

SimpleAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleAppBar);
