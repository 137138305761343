export default (theme) => ({
  layer: {
    minHeight: 'calc(100vh - 30px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mainContainer: {
    margin: '30px 0',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  supportTextContainer: {
    '& p': {
      fontSize: 18,
      color: "#504F53",
      textAlign: 'center',
      fontFamily: "'Avenir-Roman'",
    },
    marginBottom: 10
  },
  forgotPasswordContainer: {
    paddingTop: 70
  },
  mainLogoContainer: {
    marginBottom: 45
  },
  mainForm: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 300
  },
  formControl: {
    marginBottom: 25,
    '&:last-child': {
      marginBottom: 0
    }
  },
  submitButton: {
    width: 300,
    height: 50,
    fontFamily: "Avenir",
    fontSize: 18
  },
  passwordField: {
    fontSize: 60
  },
  forgotPasswordLink: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '25px',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontFamily: "Avenir",
    '&:hover': {
      color: '#8A2467'
    },
    '&:active': {
      color: '#6F1D53',
    }
  },
  linkArrow: {
    marginRight: 10,
  },
  linkText: {
    textDecoration: 'underline'
  },
  circularProgress: {
    color: 'white',
    width: '20px !important',
    height: '20px !important'
  }
});