import React from 'react';

import { withStyles } from '@material-ui/core';

import styles from './styles';

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TradeSheetsItem from "../../TradeSheets/Item";
import Paper from "@material-ui/core/Paper";

export default withStyles(styles)((props) => {
  const {
    classes,
    tradeSheets,
    handleExportTradeSheet
  } = props;
  return (
    <Paper className={classes.tableContainer}>
      {tradeSheets.length === 0 ?
        <p style={{textAlign: 'center', fontFamily: 'Arial'}}>Generate Trade Sheet before</p>
        :
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.bottomHeading}>
              <TableCell style={{paddingLeft: 38}}>ID</TableCell>
              <TableCell>Datum</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Order Type</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {tradeSheets.map((tradeSheet) => {
              return <TradeSheetsItem
                key={tradeSheet.id}
                tradeSheet={tradeSheet}
                handleExportTradeSheet={handleExportTradeSheet}
              />
            })}
          </TableBody>
        </Table>
      }
    </Paper>
  )
})