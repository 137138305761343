export const getFileNameFromResponse = (response) => {
  if (response.headers['content-disposition'] && response.headers['content-disposition'].indexOf('attachment') !== -1) {
    let fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = fileNameRegex.exec(response.headers['content-disposition']);
    if (matches != null && matches[1]) { 
      let filename = matches[1].replace(/['"]/g, '');
      return filename;
    }
  }
  return false;
}