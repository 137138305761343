import React from 'react';

import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import styles from './styles';
import FundDataItem from '../Item';
import FundVolumeItem from '../Item/volume';

export default withStyles(styles)((props) => {
  const { classes, fund } = props;

  return (
    <Grid className={classes.tableContainer}>
      <Table className={classes.fundTable} style={{maxWidth: 1240,minWidth: 1200}}>
        <TableHead>
          <TableRow className={classes.topHeading}>
            <TableCell style={{width: 150}}>Fund Volume</TableCell>
            <TableCell>Fund Inflow(+) Outflow(-)</TableCell>
            <TableCell>New Fund Volume</TableCell>
            <TableCell></TableCell>
            <TableCell>Approved:</TableCell>
            <TableCell>Executed:</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <FundVolumeItem
            fund={fund}
            handleApprove={props.handleApprove}
            handleExecute={props.handleExecute}
            handleReset={props.handleReset}
            actionOnTheFund={props.actionOnTheFund}
            handleInflowOutflowChange={props.handleInflowOutflowChange}
          />
        </TableBody>
      </Table>
      <Table className={classes.fundTable} style={{maxWidth: 1240,minWidth: 1200}}>
        <TableHead>
          <TableRow className={classes.topHeading}>
            <TableCell rowSpan={2}>Date</TableCell>
          <TableCell colSpan={4}>Performance</TableCell>
          <TableCell colSpan={3}>Is</TableCell>
          <TableCell colSpan={3}>Should %</TableCell>
          <TableCell colSpan={3}>Trade results %</TableCell>
          <TableCell rowSpan={2} style={{width: 80}}>Risk Budget %</TableCell>
          <TableCell colSpan={3} style={{width: 120}}>Overnight Risk %</TableCell>
          <TableCell rowSpan={2}>LPM0</TableCell>
        </TableRow>
        <TableRow className={classes.bottomHeading}>
          <TableCell>1Day</TableCell>
          <TableCell>MTD</TableCell>
          <TableCell>YTD</TableCell>
          <TableCell>P.A.</TableCell>
          <TableCell>Stocks</TableCell>
          <TableCell>Bonds</TableCell>
          <TableCell>Cash</TableCell>
          <TableCell style={{width: 100}}>Stocks</TableCell>
          <TableCell style={{width: 100}}>Bonds</TableCell>
          <TableCell style={{width: 100}}>Cash</TableCell>
          <TableCell>Stocks</TableCell>
          <TableCell>Bonds</TableCell>
          <TableCell>Cash</TableCell>
          <TableCell>Total</TableCell>
          <TableCell>Equity</TableCell>
          <TableCell>Fixed Income</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
          <FundDataItem fund={fund}/>
        </TableBody>
      </Table>
    </Grid>
  );
})